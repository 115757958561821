@import '../../common.scss';


@function responsiveSizeV3($sizeInPixel, $ratio: 1) {
  $unit: px;
  @return calc(#{$sizeInPixel} / 1038 * var(--mainContentHeight));
}

.hp-layoutV2.ks-homepageV2 #mainContentScreen.ks-content .ks-studentprofil {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  &-close-btn {
    cursor: pointer;
    position: absolute;
    top: responsiveSizeV3(11);
    right: responsiveSizeV3(11);
    width: responsiveSizeV3(83);
    height: responsiveSizeV3(83);
    circle {
      fill: #F2F2F2;
    }
  }
  .ks-studentprofil-right {
    display: flex;
    justify-content: flex-end;
    width: responsiveSizeV3(561);
    height: responsiveSizeV3(916);
  }
  .ks-studentprofil-left {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: responsiveSizeV3(623);
    height: responsiveSizeV3(916);
    border-radius: responsiveSizeV3(20);
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    .mainBtn.save {
      width: responsiveSizeV3(286);
      height: responsiveSizeV3(67);
      font-family: 'Fredoka One';
      font-size: responsiveSizeV2(28);
      margin-top: responsiveSizeV3(15);
      border-radius: responsiveSizeV3(15);
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    }
    .mainBtn.reinit {
      font-family: 'Avenir';
      text-transform: uppercase;
      background-color: #CE6F3D;
      width: responsiveSizeV3(314);
      height: responsiveSizeV3(46);
      font-size: responsiveSizeV2(20);
      margin-top: responsiveSizeV3(20);
      border-radius: responsiveSizeV3(7);
    }
    .ks-form {
      width: 90%;
      margin-top: responsiveSizeV3(30);
      .ks-form-group {
        width: 100%;
        &:not(:first-child) {
          margin-top: responsiveSizeV3(43);
        }
        .form-label {
          color: #51AABC;
          text-align: center;
          font-family: 'Itim';
          font-size: responsiveSizeV3(32);
        }
        select,
        input {
          width: 100%;
          border: none;
          padding-left: 0;
          text-align: center;
          font-family: 'Itim';
          border-radius: responsiveSizeV3(12);
          font-size: responsiveSizeV3(32);
          height: responsiveSizeV3(65);
        }
        select {
          width: 100%;
          appearance: none !important;
          padding-left: responsiveSizeV3(40) !important;
          padding-right: responsiveSizeV3(40) !important;
          text-overflow: ellipsis;
          -moz-appearance:none !important;
          -webkit-appearance: none !important;
        }
        &.select-input {
          position: relative;
          .select-arrow {
            bottom: responsiveSizeV3(20);
            right: responsiveSizeV3(20);
            position: absolute;
            width: responsiveSizeV3(15);
            height: responsiveSizeV3(24);
          }
        }
      }
    }
  }

}
@import '../../common.scss';

.ks-topNavigation {
  font-family: 'Inter';
  color: #C3C3C3;
  position: absolute;
  right: 0;
  top: 0;
  background-color: black;
  display: flex;
  align-items: center;
  z-index: 8;
  @include responsive-size(height, 82);
  @include responsive-size(font-size, 25);
  @include responsive-size(border-bottom-left-radius, 45);
  .ks-usernameArrow {
    display: flex;
    align-items: center;
  }
  a,
  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    @include responsive-size(margin-left, 30);
    @include responsive-size(margin-right, 30);
    @include responsive-size(width, 150);
  }
  a {
    text-overflow: initial;
    color: white;
    width: fit-content;
    @include responsive-size(font-size, 20);
  }
  svg {
    @include responsive-size(height, 52);
    @include responsive-size(width, 52);
    &.ks-iconExit {
      border-right: 1px solid #C3C3C3;
      @include responsive-size(margin-left, 23);
      @include responsive-size(padding-right, 22);
    }
  }

  .iconArrow {
    @include responsive-size(height, 23);
    @include responsive-size(width, 14);
    @include responsive-size(padding, 12);
  }

  svg.iconArrow {
    transition: 0.5s all;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -o-transform: rotate(0);
    -ms-transform: rotate(0);
    transform: rotate(0);
  }

  &.open {
    svg.iconArrow {
      transition: 0.5s all;
      -webkit-transform: rotate(-90deg);
      -moz-transform: rotate(-90deg);
      -o-transform: rotate(-90deg);
      -ms-transform: rotate(-90deg);
      transform: rotate(-90deg);
    }
  }

  .ks-chapterMapContent & {
    .ks-iconExit {
      path[fill="#c39745"] {
        fill: #5BAFBF;
      }
    }
  }

  .revision & {
    .ks-iconExit {
      path[fill="#c39745"] {
        fill: #53B2A0;
      }
    }
  }

  .exercise & {
    .ks-iconExit {
      path[fill="#c39745"] {
        fill: #4AA5B7;
      }
    }
  }
  .LetterWrittenInName & {
    .ks-iconExit {
      path[fill="#c39745"] {
        fill: #EEBF82;
      }
    }
  }
}
.subMenu {
  height: fit-content;
  @include responsive-size(border-bottom-left-radius, 45);
  @include responsive-size(width, 352.156);
  label {
    cursor: pointer;
    color: #C3C3C3;
    font-weight: 300;
    @include responsive-size(font-size, 25);
    @include responsive-size(letter-spacing, -0.5);
    text-transform: none;
  }
  .logout {
    width: 100%;
    margin: 0;
    padding: 0;
    min-width: 0;
    border-radius: 0;
    @include responsive-size(padding-top, 5);
    @include responsive-size(padding-bottom, 25);
    label {
      color: #99717E;
      line-height: 1;
    }
  }
}

.subMenu {
  right: 0;
  background-color: black;
  position: absolute;
  overflow: hidden;
  top: 0;
  z-index: 7;
  @include responsive-size(padding-top, 81);
  & > div {
    display: flex;
    align-items: center;
  }
  label {
    @include responsive-size(margin-left, 30);
    cursor: pointer;
  }
  -moz-transition: max-height 0.5s;
  -ms-transition: max-height 0.5s;
  -o-transition: max-height 0.5s;
  -webkit-transition: max-height 0.5s;
  transition: max-height ease 0.5s;

  &.ks-subMenuOpen {
    max-height: 300px;
  }
  &.ks-subMenuClose {
    max-height: 0;
  }
  .memberLink {
    justify-content: center;
    .memberAccess {
      text-decoration: none;
      line-height: 1;
      label {
        @include responsive-size(padding-bottom, 30);
      }
    }
  }
}
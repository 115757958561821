@import "../../../../common.scss";

.section1 {
  width: 100%;
  background: linear-gradient(to bottom, #F0C55F responsiveSizeV2(696), white);

  .section1-content {
    margin: auto;
    width: responsiveSizeV2(952);

    .section1-try-it {
      display: flex;
      color: black;
      margin-bottom: responsiveSizeV2(40);

      .section1-try-it-mobile-title {
        display: none;
      }

      img.img-binti-study {
        width: responsiveSizeV2(648);
        height: responsiveSizeV2(556);
        margin-left: responsiveSizeV2(-100);
      }

      h1 {
        margin-top: responsiveSizeV2(75);
        margin-bottom: responsiveSizeV2(12);
        font-size: responsiveSizeV2(50);
        line-height: responsiveSizeV2(50);
        font-family: 'minion-pro';
      }

      p {
        line-height: responsiveSizeV2(27);
        font-family: 'Avenir';
        font-size: responsiveSizeV2(24);
        font-weight: 300;
        margin: 0 0 responsiveSizeV2(15) 0;

        strong {
          font-weight: 900;
        }
      }
      a,
      button {
        font-weight: 700;
        font-family: 'Avenir';
        font-size: responsiveSizeV2(22);
        color: white;
        background-color: black;
        padding: responsiveSizeV2(6) responsiveSizeV2(40);
        text-decoration: none;
        border: none;
        border-radius: responsiveSizeV2(4);
      }
    }

    hr {
      margin: 0;
      width: 100%;
      border: none;
      border-top: 1px solid black;
    }

    .section1-participate {
      display: flex;
      margin-top: responsiveSizeV2(23);
      position: relative;

      .col1 {
        width: 50%;
        color: black;
        font-weight: bold;
        font-family: 'minion-pro';
        font-size: responsiveSizeV2(45);
        line-height: responsiveSizeV2(45);
        min-height: responsiveSizeV2(190);
        padding-left: responsiveSizeV2(50);
        margin-bottom: responsiveSizeV2(29);

        .lf-player-container {
          width: responsiveSizeV2(164);
          height: responsiveSizeV2(164);
          top: responsiveSizeV2(20);
          left: responsiveSizeV2(340);
          position: absolute;
        }
      }

      .col2 {
        width: 50%;
        display: flex;
        box-sizing: border-box;
        flex-direction: column;
        padding-left: responsiveSizeV2(34);
        a {
          width: responsiveSizeV2(372);
          height: responsiveSizeV2(61);
          font-family: 'Avenir';
          font-weight: 700;
          font-size: responsiveSizeV2(30);
          text-decoration: none;
          text-transform: uppercase;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: responsiveSizeV2(4);
          margin-top: responsiveSizeV2(22);

          &.donation {
            color: #51AABC;
            background-color: white;
          }

          &.partner {
            color: white;
            background-color: #51AABC;
          }
        }
      }
    }
  }

}

@import '../../common.scss';

.ks-icon-sound {
  position: absolute;
  @include responsive-size(top, 22);
  @include responsive-size(right, 14);
  @include responsive-size(height, 40);
  @include responsive-size(width, 40);
  svg,
  .ks-audio-playing,
  .ks-audio-playing .css-0 {
    height: 100%;
    width: 100%;
    @include responsive-size(border-radius, 60);
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  }
  .ks-audio-playing {
    background-color: #51AABC;
    .css-0 {
      display: flex;
      align-items: center;
      justify-content: center;
      .css-1by9rry,
      .css-110do72 {
        height: 20%;
        width: 20%;
        margin: 3%;
        background-color: white;
      }
    }
  }
}
@import '../../common.scss';

.hp-layoutV2 {
  &.ks-chapterMap {
    .ks-loadingPage {
      width: 100%;
      height: 100%;
    }
    .ks-chapterMapContent.ks-chapter_0 .ks-imageMap {
      margin-top: 4%; 
    }
  }
  .ks-chapterMapLoading {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.ks-chapterMapContent {
  position: relative;
  display: flex;
  width: 100.5%;
  height: 100.5%;
  overflow: hidden;
  .ks-flipImage {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  }
  .ks-imageBottomLeft, 
  .ks-imageBottomRight {
    position: absolute;
    bottom: -5px;
    z-index: 2;
    &, img.ks-imageChapterMap {
      @include responsive-size(width, 408);
      height: auto;
    }
    .ks-arrowBottomLeft,
    .ks-arrowBottomRight {
      position: absolute;
      @include responsive-size(width, 159.96);
      @include responsive-size(height, 166.21);
      @include responsive-size(bottom, 355);
    }

    &.ks-newMessage {
      z-index: 4;
      .ks-arrowBottomLeft,
      .ks-arrowBottomRight {
        animation-duration: .8s;
        animation-name: blink;
        animation-iteration-count: infinite;
        transition: none;
      }
    }
    .ks-arrowBottomLeft {
      @include responsive-size(left, 280);
    }
    .ks-arrowBottomRight {
      @include responsive-size(right, 280);
      transform: rotate(260deg);
      @include responsive-size(bottom, 270);
    }
    @keyframes blink {
      0%   { opacity:1; }
      40%   {opacity:0; }
      100% { opacity:1; }
    }
  }
  .ks-imageBottomLeft {
    z-index: 4;
    left: 0;
  }
  .ks-imageBottomRight {
    right: 0;
  }
  .ks-leftPanel {
    z-index: 1;
    background-color: transparent;
    .ks-downloadBook, 
    .ks-waladCardsBtn {
      
      svg {
        @include responsive-size(width, 115);
        @include responsive-size(height, 115);
      }
    }
    .ks-usnername {
      background-color: white;
      color: black;
      line-height: 1;
      @include responsive-size(width, 105);
      @include responsive-size(height, 33);
      @include responsive-size(font-size, 14);
      @include responsive-size(margin-top, 8);
    }
    .ks-trophy {
      @include responsive-size(margin-top, 15);
      svg {
        @include responsive-size(width, 115.65);
        @include responsive-size(height, 115.65);
      }
    }
  }
  .ks-chapterTitleImage {
    width: 84.6%;
    height: fit-content;
    z-index: 3;
    margin-top: 7.2%;
    .ks-mapTitle {
      font-family: 'Itim';
      margin-bottom: 1%;
      width: fit-content;
      transform: translateX(60%) rotate(-1deg);
      @include responsive-size(font-size, 37);
      @include responsive-size(padding-left, 13.8);
      @include responsive-size(padding-right, 13.8);
      @include responsive-size(padding-bottom, 3);
      @include responsive-size(border-radius, 8);
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
      background-color: #EAC676;
    }
    .ks-imageMap > div svg {
      width: 100%;
      height: 100%;
      g text:hover,
      g[id^="session_"] {
        cursor: not-allowed;
      }
      g.ks-session-enabled text:hover,
      g[id^="session_"].ks-session-enabled {
        cursor: pointer;
      }
    }
    .ks-imageMap.ks-disabled > div svg {      
      g.ks-session-enabled text:hover,
      g[id^="session_"].ks-session-enabled {
        cursor: not-allowed;
      }
    }
  }
  .ks-alertRecordVoice {
    position: absolute;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    &, svg {
      height: 100%;
      width: 100%;
    }
    .ks-alertRecordVoice-message {
      position: absolute;
      padding: 1.8%;
      color: white;
      background-color: black;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
      text-align: center;
      bottom: 12%;
      margin-right: 8%;
      @include responsive-size(border-radius, 33);
      svg {
        position: absolute;
        @include responsive-size(width, 35);
        @include responsive-size(height, 35);
        right: 3%;
        top: 4.5%;
      }
      .ks-alertRecordVoice-title {
        color: #51AABC;
        font-family: 'Fredoka One';
        @include responsive-size(font-size, 46);
      }
      .ks-alertRecordVoice-desc {
        font-family: 'Itim';
        line-height: 1.1;
        margin-top: 3%;
        margin-bottom: 6%;
        @include responsive-size(font-size, 44);
      }
      .ks-alertRecordVoice-btn {
        display: flex;
        justify-content: space-evenly;
        .ks-alertRecordVoice-btn1,
        .ks-alertRecordVoice-btn2 {
          background-color: black;
          border: 1px solid white;
          @include responsive-size(font-size, 20);
        }
        .ks-alertRecordVoice-btn1 {
          @include responsive-size(width, 168);
        }
        .ks-alertRecordVoice-btn2 {
          @include responsive-size(width, 253);
        }
        .ks-alertRecordVoice-primaryBtn {
          @include responsive-size(width, 132);
          @include responsive-size(font-size, 25);
        }
      }
    }
  }

  &.ks-chapter_0 {
    .ks-imageMap {
      @include responsive-size(width, 1123);
      @include responsive-size(height, 874);
      @include responsive-size(margin-top, 125);
      @include responsive-size(margin-left, 92);
      g[id^="ETAPE"] {
        display: none;
      }
      g.ks-session-enabled {
        rect[id^="Rectangle_618"] {
          fill: white;
          stroke: #51AABC;
          stroke-width: 2px;
        }
        text[fill="#d8a041"], path[fill="#d8a041"] {
          fill: #51AABC;
        }
      }
      g.ks-session-current {
        g[id^="ETAPE"] {
          display: block;
        }
        rect[id^="Rectangle_618"] {
          fill: white;
          stroke-width: 0;
        }
        text[fill="#d8a041"], path[fill="#d8a041"] {
          fill: black;
        }
      }
    }
  }

  .ks-arrowNext,
  .ks-arrowPrevious {
    display: none;
  }

  &.ks-chapter_2 {
    .ks-chapterTitleImage {
      margin-top: 13%;
      margin-left: 5%;
    }
  }

  &.ks-vocabulaire {
    .ks-iconExit {
      path[fill="#c39745"] {
        fill: #EFA279;
      }
    }
    .ks-imageBottomLeft,
    .ks-imageBottomRight {
      display: none;
    }
    &.ks-chapter_2 .ks-chapterTitleImage {
      margin-left: 0;
    }
    .ks-chapterTitleImage {
      width: 100%;
      display: flex;
      margin-top: 18%;
      justify-content: center;
      align-items: center;
      position: absolute;
      .ks-arrowNext,
      .ks-arrowPrevious {
        display: block;
        &, svg {
          @include responsive-size(width, 29);
          @include responsive-size(height, 60);
        }
      }
      .ks-arrowNext {
        @include responsive-size(margin-left, 25);
      }
      .ks-arrowPrevious {
        @include responsive-size(margin-right, 25);
      }
      .ks-imageMap {
        width: 86.4%;
        overflow-x: scroll;
        overflow-y: hidden;
        scrollbar-width: none; /* Firefox */
        -ms-overflow-style: none;  /* Internet Explorer 10+ */
        &::-webkit-scrollbar { /* WebKit */
          width: 0;
          height: 0;
        }
        &, svg {
          @include responsive-size(height, 524.255, 1);
        }
        svg {
          width: auto !important;
          g[id^="session_"] {
            opacity: 0.5;
          }
          g[id^="session_"].ks-session-enabled {
            g[id^="number"] {
              g[fill="#fff"] {
                fill: #0A5798;
              }
              path[fill="#0a5798"] {
                fill: white;
              }
            }
            path[fill="#174365"] {
              fill: white;
            }
            g[data-name^="bkg_"] {
              stroke: white;
              fill: #0A5798;
            }
            &.ks-session-current {
              g[id^="number"] {
                g[fill="#fff"] {
                  fill: #fff;
                }
                path[fill="#0a5798"] {
                  fill: #0a5798;
                }
              }
              path[fill="#174365"] {
                fill: #174365;
              }
              g[data-name^="bkg_"] {
                stroke: #ddd;
                fill: white;
              }
            }
            &,
            g[id^="session_"] {
              opacity: 1;
              cursor: pointer;
            }
            g {
              &#lock,
              &[data-name="lock"] {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}
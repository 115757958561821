@import '../../../common.scss';

.hp-layoutV2 {
  .ks-tuto-control {
    height: auto;
    display: none;
    box-sizing: border-box;
    background-color: white;
    border-radius: responsiveSizeV2(32);
    width: responsiveSizeV2(281);
    height: responsiveSizeV2(151);
    &.open {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
    .ks-tuto-control-close {
      position: absolute;
      top: responsiveSizeV2(10);
      left: responsiveSizeV2(13);
      width: responsiveSizeV2(35);
      height: responsiveSizeV2(35);
    }
    .ks-tuto-control-btn {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      margin-bottom: responsiveSizeV2(12);
      .ks-tuto-control-left,
      .ks-tuto-control-right {
        width: responsiveSizeV2(75);
        height: responsiveSizeV2(75);
      }
      .ks-tuto-control-right {
        transform: rotate(180deg);
      }
      .ks-tuto-control-play,
      .ks-tuto-control-pause {
        height: responsiveSizeV2(75);
        width: responsiveSizeV2(75);
      }
    }
    .ks-tuto-control-progress {
      color: #F07C6D;
      font-family: 'Fredoka One';
      font-size: responsiveSizeV2(28);
    }
  }
}
@import '../../common.scss';

.hp-layoutV2 {
  #mainContentScreen {
    .ks-mainPanel {
      &, .ks-mainScreen {
        width: 100%;
        height: 100%;
      }
    }
  }
  .ks-tutoExplanation {
    display: none;
  }
  .ks-sessionLayoutV2-loading {
    width: var(--mainContentWidth);
    height: var(--mainContentHeight);
    position: inherit;
  }
}
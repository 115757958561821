@import '../../common.scss';

.hp-layoutV2.ks-homepageV2 #mainContentScreen.ks-content .hp-homepageV3 {
  display: flex;
  height: fit-content;
  flex-direction: column;
  justify-content: space-between;
  width: responsiveSizeV2(1240);
  .hp-homepageV3-top {
    display: flex;
    justify-content: space-between;
    margin-top: responsiveSizeV2(153);
    svg {
      width: auto;
      cursor: pointer;
      height: responsiveSizeV2(621);
    }
  }

  .phase-btn {
    position: relative;
    span {
      color: black;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: 'Fredoka One';
      top: responsiveSizeV2(210);
      left: responsiveSizeV2(50);
      background-color: white;
      width: responsiveSizeV2(180);
      height: responsiveSizeV2(48);
      font-size: responsiveSizeV2(21);
      border-radius: responsiveSizeV2(11);
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    }
    &.phase3 span {
      left: responsiveSizeV2(210);
    }
  }

  .hp-homepageV3-bottom {
    display: flex;
    color: #FFFFFF;
    font-family: 'Itim';
    justify-content: center;
    font-size: responsiveSizeV2(25);
    margin-top: responsiveSizeV2(224);
    margin-bottom: responsiveSizeV2(14);
    a {
      display: flex;
      color: #1C1D1E;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      background-color: white;
      width: responsiveSizeV2(155);
      height: responsiveSizeV2(33);
      font-size: responsiveSizeV2(17);
      margin-left: responsiveSizeV2(12);
      border-radius: responsiveSizeV2(6);
    }
  }
}
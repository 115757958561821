@import "common.scss";

.corrected-container {
  direction: ltr;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: responsiveSizeV2(11);
  height: responsiveSizeV2(105);
  width: responsiveSizeV2(112);
  background-color: white;
  border-style: solid;
  &.true {
    border-color: #5a9f6d;

    &.isCurrent {
      background-color: #5a9f6d;
    }
  }
  &.false {
    border-color: #ce6f3d;
    &.isCurrent {
      background-color: #ce6f3d;
    }
  }
  .letter {
    width: 100%;
    height: 100%;
    svg {
      height: inherit;
      width: 100%;
      cursor: pointer;
    }
  }

  .current-arrow {
    position: absolute;
    transform: translate(responsiveSizeV2(73), 0%);
    height: responsiveSizeV2(25);
  }
}
@media only screen and (max-width: 970px) {
  .corrected-container {
    height: responsiveSizeV2(100);
  }
}

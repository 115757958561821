@import '../../common.scss';

.ks-repeatableImageAudio {
  .ks-modalContent {
    position: relative;
  }
  .ks-modalContent, svg {
    @include responsive-size(width, 1284);
    @include responsive-size(height, 832);
  }
  .ReactModal__Overlay {
    z-index: 49;
    background-color: none !important;
    background-image: linear-gradient(rgba(81, 170, 188, 0.83), rgba(156, 165, 163, 0.83)) !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ReactModal__Content {
    inset: auto !important;
    border: none !important;
    background: none !important;
    width: fit-content;
    height: fit-content;
    top: auto !important;
    left: auto !important;
    bottom: auto !important;
    right: auto !important;
  }

  .ks-disabled {
    opacity: 1 !important;
  }

  .ks-modalBtn {
    position: absolute;
    display: flex;
    flex-direction: column;
    @include responsive-size(top, 541);
    @include responsive-size(right, 286);
    .mainBtn {
      font-family: 'Fredoka One';
      @include responsive-size(font-size, 35);
      @include responsive-size(width, 274);
      @include responsive-size(height, 75);
      @include responsive-size(border-radius, 75);
      @include responsive-size(margin-bottom, 13);
      &.ks-repeatBtn {
        color: black;
        background-color: white;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
        @include responsive-size(font-size, 29);
      }
    }
  }
  
}
@import '../../common.scss';

.hp-layoutV2.ks-homepageV2.hp-layoutV3 {
  .hp-leftArea {
    margin-top: 0;
    position: relative;
  }

  .hp-headerArea {
    display: none;
  }

  .ks-awlad-logo {
    width: responsiveSizeLegacyToV2(313);
    height: responsiveSizeLegacyToV2(125);
  }

  .ks-awlad-profiles {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: responsiveSizeLegacyToV2(306);
    margin-top: responsiveSizeLegacyToV2(48);
    min-height: responsiveSizeLegacyToV2(434);

    &-loader {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &-item {
      display: flex;
      cursor: pointer;
      position: relative;
      flex-direction: column;
      align-items: center;
      width: responsiveSizeLegacyToV2(133);
      margin-bottom: responsiveSizeLegacyToV2(22);

      .ks-awlad-profiles-item-img,
      .ks-awlad-profiles-item-img svg {
        cursor: pointer;
        width: responsiveSizeLegacyToV2(133);
        height: responsiveSizeLegacyToV2(133);
        border-radius: responsiveSizeLegacyToV2(133);
      }

      svg.ks-awlad-profiles-item-triangle {
        display: none;
        position: absolute;
        left: responsiveSizeLegacyToV2(-28);
        width: responsiveSizeLegacyToV2(19);
        height: responsiveSizeLegacyToV2(29);
      }

      &-setting-icon {
        top: 0;
        right: 0;
        cursor: pointer;
        position: absolute;
        display: none;
        width: responsiveSizeLegacyToV2(33);
        height: responsiveSizeLegacyToV2(33);
      }

      span {
        display: flex;
        color: #808080;
        align-items: center;
        justify-content: center;
        position: relative;
        text-align: center;
        font-family: 'Inter';
        border: 2px solid #484848;
        background-color: #1C1D1E;
        width: responsiveSizeLegacyToV2(120);
        height: responsiveSizeLegacyToV2(37);
        font-size: responsiveSizeLegacyToV2(18);
        margin-top: responsiveSizeLegacyToV2(18);
        border-radius: responsiveSizeLegacyToV2(5);
      }

      &.selected {
        span {
          color: #000;
          border-color: #FFFFFF;
          background-color: #FFFFFF;
        }

        svg #AVATAR_01-2 {
          fill: #FFFFFF;
        }

        svg.ks-awlad-profiles-item-triangle,
        svg.ks-awlad-profiles-item-setting-icon {
          display: block;
        }
      }
    }
  }

  .ks-awlad-main-nav {
    display: flex;
    text-align: center;
    flex-direction: column;
    justify-content: space-between;
    height: responsiveSizeV2(265);
    margin-top: responsiveSizeV2(89);
    &.authenticated {
      justify-content: space-evenly;
      height: responsiveSizeV2(219);
      margin-top: responsiveSizeV2(20);
      padding-top: responsiveSizeV2(22);
      padding-bottom: responsiveSizeV2(29);
      border-top: 1.5px solid rgba(255, 255, 255, 0.2);
      border-bottom: 1.5px solid rgba(255, 255, 255, 0.2);
    }
    a, button {
      border: none;
      cursor: pointer;
      color: #C3C3C3;
      background: none;
      font-family: 'Itim';
      text-decoration: none;
      text-transform: uppercase;
      font-size: responsiveSizeV2(28);
      &.selected {
        color: #EAC676;
      }
    }
  }

  .ks-awlad-info-btn {
    margin-top: 0;
    position: absolute;
    right: responsiveSizeV2(25);
    width: responsiveSizeV2(330);
    height: responsiveSizeV2(330);
    bottom: responsiveSizeV2(60);
  }

  .ks-awlad-back-hoopow-btn {
    cursor: pointer;
    width: responsiveSizeLegacyToV2(296);
    height: responsiveSizeLegacyToV2(92);
    margin-top: responsiveSizeLegacyToV2(28);
  }
}
@import '../../../common.scss';

.ks-unLockLesson {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  .ks-unLockLesson-background {
    z-index: 1;
    width: 100%;
    height: 100%;
    opacity: 0.8;
    position: absolute;
    background-color: #12414A;
  }
  .ks-unLockLesson-frame {
    z-index: 10;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    position: relative;
    background-color: white;
    width: responsiveSizeLegacyToV2(1039);
    height: responsiveSizeLegacyToV2(659);
    border-radius: responsiveSizeLegacyToV2(50);
    text-align: center;
    .ks-unLockLesson-frame-close {
      z-index: 10;
      cursor: pointer;
      position: absolute;
      top: responsiveSizeLegacyToV2(21);
      right: responsiveSizeLegacyToV2(28);
      width: responsiveSizeLegacyToV2(84);
      height: responsiveSizeLegacyToV2(84);
    }
    .ks-unLockLesson-frame-lock {
      width: responsiveSizeLegacyToV2(46);
      height: responsiveSizeLegacyToV2(62);
      margin-bottom: responsiveSizeLegacyToV2(25);
    }
    .ks-unLockLesson-frame-desc {
      font-family: 'Fredoka One';
      font-size: responsiveSizeLegacyToV2(32);
      margin-bottom: responsiveSizeLegacyToV2(35);
    }
    .ks-unLockLesson-frame-subdesc {
      font-weight: 300;
      font-family: 'Avenir';
      font-size: responsiveSizeLegacyToV2(30);
      margin-bottom: responsiveSizeLegacyToV2(41);
    }
    .mainBtn {
      font-weight: 100;
      font-family: 'Avenir';
      width: responsiveSizeLegacyToV2(365);
      height: responsiveSizeLegacyToV2(56);
      font-size: responsiveSizeLegacyToV2(25);
      border-radius: responsiveSizeLegacyToV2(15);
      &.cancel {
        color: black;
        background-color: #FCC566;
        margin-bottom: responsiveSizeLegacyToV2(21);
      }
      &.unlock {
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #17443C;
      }
    }
  }
}
@import '../../../common.scss';

.hp-layoutV2 {
  .ks-number-pad {
    display: flex;
    justify-content: center;
    align-items: center;
    width: responsiveSizeV2(326);
    height: responsiveSizeV2(349);
    g[id^="SUCCESS_"],
    g[id^="FAIL_"],
    g[id^="number"] {
      display: none;
    }
    .ks-interface-selected,
    .ks-interface-unlocked {
      display: block !important;
      path[id^="padlock"] {
        display: none;
      }
      &.ks-interface-fail {
        g[id^="FAIL_"] {display: block}
      }
      &.ks-interface-success {
        g[id^="SUCCESS_"] {display: block}
      }
    }
    .ks-interface-selected {
      path[id^="number"] {
        fill: white;
      }
      circle[id^="ellipse"] {
        fill: #51aabc;
      }
    }
    .ks-interface-unlocked {
      cursor: pointer;
      &.ks-interface-new-enabled {
        animation-duration: .8s;
        animation-name: blink;
        animation-iteration-count: infinite;
        circle[id^="ellipse"] {
          fill: #F07C6D;
          stroke: #F07C6D;
        }
        path[id^="number"] {
          fill: white;
        }
      }
      @keyframes blink {
        0%   { opacity:1; }
        40%   {opacity:0; }
        100% { opacity:1; }
      }
      z-index: 1;
    }
    .ks-interface-locked {
      display: block !important;
      path[id^="number"] {
        display: none;
      }
      circle[id^="ellipse"] {
        stroke: #c3c3c3;
      }
    }
  }
}
@import "common.scss";

.tooltip-wrapper {
  display: inline-block;
  position: relative;
  .tip {
    font-family: "Itim";

    width: responsiveSizeV2(175);
    position: absolute;
    text-align: center;
    border-radius: responsiveSizeV2(15);
    left: responsiveSizeV2(60);
    transform: translateX(-50%);
    padding: responsiveSizeV2(18) responsiveSizeV2(40);
    margin-top: responsiveSizeV2(20);
    color: white;
    background-color: #51aabc;
    border: responsiveSizeV2(4) solid #51aabc;
    font-size: responsiveSizeV2(26);
    line-height: 1;
    z-index: 100;
    &.disabled {
      display: none;
    }
    &.v2:not(.disabled) {
      justify-content: center;
      align-content: center;
      display: flex;
      align-items: center;

      width: responsiveSizeV2(169);
      height: responsiveSizeV2(52);
      margin: responsiveSizeV2(14);
      padding: responsiveSizeV2(30) responsiveSizeV2(0);

      background: transparent;
      color: #51aabc;
      border: responsiveSizeV2(3) solid #51aabc;
      &.mic {
        left: 19%;
        &::before {
          left: 54%;
        }
      }
      &.player {
        left: 32%;
        &::before {
          clip-path: polygon(60% 50%, 0% 100%, 100% 100%);
        }
      }
      &.send {
        &::before {
          clip-path: polygon(20% 50%, 0% 100%, 100% 100%);
        }
      }

      &::before {
        clip-path: polygon(80% 50%, 0% 100%, 100% 100%);
        left: 44%;
        border-width: responsiveSizeV2(10);
      }
    }
    &::before {
      content: "";
      clip-path: polygon(50% 60%, 20% 100%, 80% 100%);
      left: 44%;
      position: absolute;
      pointer-events: none;
      border: solid #51aabc;
      border-width: responsiveSizeV2(22);
    }
    &.bottom {
      &::before {
        bottom: 100%;
        margin-bottom: responsiveSizeV2(1.5);
      }
    }
    &.isRecording {
      width: responsiveSizeV2(213);
      padding: responsiveSizeV2(32) responsiveSizeV2(7);
    }
  }
  .player {
    height: responsiveSizeV2(67);
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
  }
}

@import "common.scss";

.modal-box {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: responsiveSizeV2(50);

  .modal-title {
    font-style: normal;
    font-weight: normal;
    font-family: "Fredoka One";
    font-size: responsiveSizeV2(42);
    color: #51aabc;
    line-height: responsiveSizeV2(52);
  }

  .modal-content {
    font-family: "Itim";
    display: flex;
    flex-direction: column;
    padding: 0 responsiveSizeV2(100);
    margin-bottom: responsiveSizeV2(30);
    p {
      font-size: responsiveSizeV2(36);
      line-height: responsiveSizeV2(36);
      margin-bottom: 0;
    }
  }
  .profile-options {
    margin-left: responsiveSizeV2(60);
  }
  .modal-footer {
    bottom: 0px;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: responsiveSizeV2(28);
    justify-content: center;
    button {
      width: responsiveSizeV2(471);
      height: responsiveSizeV2(65);
      font-family: "Fredoka One";
      border-radius: responsiveSizeV2(41);
      cursor: pointer;
      background-color: #51aabc;
      color: white;
      padding: responsiveSizeV2(20) responsiveSizeV2(35);
      margin: responsiveSizeV2(10);
      border: none;
      font-size: responsiveSizeV2(24);
      .disabled {
        opacity: 20;
      }
    }
  }
}

@import "common.scss";

.modal-box {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: responsiveSizeV2(50);
  &.all-sent {
    .modal-title {
      font-size: responsiveSizeV2(52);
    }
    .modal-content {
      p {
        font-size: responsiveSizeV2(41);
        line-height: responsiveSizeV2(45);
      }
    }
  }
  &.welcome {
    .modal-title {
      font-size: responsiveSizeV2(44);
    }
    .modal-footer {
      padding-top: responsiveSizeV2(34);
      button {
        font-size: responsiveSizeV2(30);
        padding: responsiveSizeV2(16) responsiveSizeV2(35);
      }
    }
  }
  &.warning-modal {
    .modal-footer {
      button {
        &.primary {
          padding: responsiveSizeV2(20) responsiveSizeV2(53);
        }
        &.secondary {
          padding: responsiveSizeV2(17) responsiveSizeV2(20);
        }
      }
    }
  }
  &.hasIcon {
    flex-direction: row;
    .modal-body {
      align-self: center;
    }
    .modal-content {
      padding: 0 responsiveSizeV2(10);
    }
  }
  .modal-title {
    font-style: normal;
    font-weight: normal;
    font-family: "Fredoka One";
    font-size: responsiveSizeV2(42);
    color: #51aabc;
    line-height: responsiveSizeV2(52);
  }
  .modal-icon {
    display: flex;
    justify-content: flex-end;
    svg {
      width: responsiveSizeV2(106.47);
      height: responsiveSizeV2(133.23);
    }
  }
  .modal-content {
    font-family: "Itim";
    display: flex;
    flex-direction: column;
    padding: 0 responsiveSizeV2(100);
    &.allCorrected {
      padding: 0 responsiveSizeV2(190);
    }
    p {
      font-size: responsiveSizeV2(36);
      line-height: responsiveSizeV2(36);
      margin-bottom: 0;
    }
  }
  .modal-footer {
    bottom: 0px;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: responsiveSizeV2(28);
    justify-content: center;
    button {
      font-family: "Fredoka One";
      border-radius: responsiveSizeV2(41);
      cursor: pointer;
      background-color: #51aabc;
      color: white;
      padding: responsiveSizeV2(20) responsiveSizeV2(35);
      margin: responsiveSizeV2(10);
      border: none;
      font-size: responsiveSizeV2(24);
      &.secondary {
        background-color: #fff;
        border: responsiveSizeV2(3) solid black;
        color: black;
      }
    }
  }
}

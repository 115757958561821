@import '../../common.scss';
.ks-member {
  background-image: url(../../assets/homepage_bg_2.svg);
  display: flex;
  font-family: 'Inter';
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  .ks-iconExit {
    path[fill="#c39745"] {
      fill: #5BAFBF;
    }
  }  
  .ks-ad-banner {
    &, img {
      width: 100%;
      @include responsive-size(height, 215);
    }
  }
  .ks-logo-chapters {
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    @include responsive-size(padding-top, 80);
    .ks-mapTitle {
      font-family: 'Itim';
      margin-bottom: 1%;
      width: fit-content;
      transform: rotate(-1deg);
      @include responsive-size(font-size, 37);
      @include responsive-size(padding-left, 13.8);
      @include responsive-size(padding-right, 13.8);
      @include responsive-size(padding-bottom, 3);
      @include responsive-size(border-radius, 8);
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.31);
      background-color: #EAC676;
    }
    .ks-logo {
      svg {
        @include responsive-size(width, 321);
        @include responsive-size(height, 135);
      }
    }
  }
  .ks-profils {
    display: flex;
    justify-content: center;
    align-items: center;
    @include responsive-size(margin-top, 19);
    .ks-mgntProfilForm {
      display: flex;
      align-items: center;
      flex-direction: column;
      width: 100%;
      box-sizing: border-box;
      @include responsive-size(padding, 45);
      background-color: white;
      > span {
        font-family: 'Avenir';
        text-align: center;
        width: 100%;
        font-weight: 900;
        color: #1A5560;
        @include responsive-size(margin-bottom, 20);
        @include responsive-size(font-size, 29);
      }
      @include responsive-size(width, 592);
      @include responsive-size(height, 583);
      @include responsive-size(border-radius, 28);
      &:not(:last-child) {
        @include responsive-size(margin-right, 35);
      }
    }
  }
}
@import "../../../../common.scss";

.section5 {
  width: 100%;
  background-color: white;

  .section5-frame {
    width: responsiveSizeV2(904);
    margin: auto;
    padding-top: responsiveSizeV2(33);

    img {
      width: responsiveSizeV2(539);
      height: responsiveSizeV2(376);
    }

    h1 {
      color: black;
      text-transform: uppercase;
      font-family: 'minion-pro';
      font-weight: bold;
      font-size: responsiveSizeV2(34);
      line-height: responsiveSizeV2(38);
      text-align: center;
      margin: 0;
    }

    .imageText {
      margin: responsiveSizeV2(38) 0;

      .imageText-text {
        display: flex;
        align-items: center;

        p {
          margin: 0;
          color: black;
          font-weight: 300;
        }
      }
    }

    .section5-cta {
      display: flex;
      justify-content: center;

      a {
        width: responsiveSizeV2(373);
        height: responsiveSizeV2(61);
        font-family: 'Avenir';
        font-weight: 700;
        font-size: responsiveSizeV2(30);
        text-decoration: none;
        text-transform: uppercase;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: responsiveSizeV2(4);
        color: white;
        background-color: #51AABC;
        margin-bottom: responsiveSizeV2(57);

        &.partner {
          margin-left: responsiveSizeV2(44);
        }
      }
    }
  }
}
@import '../../common.scss';

.hp-layoutV2.ks-homepageV2 #mainContentScreen.ks-content .hp-homepageNotAuthenticated {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  flex-direction: column;
  background: no-repeat url(../../assets/standalone/background-notauth.svg);
  background-size: cover;

  .ks-awlad-logo {
    width: responsiveSizeV2(636);
    height: responsiveSizeV2(253);
    margin-top: responsiveSizeV2(124);

    path {
      fill: black
    }
  }

  p {
    margin: 0;
    font-weight: bold;
    text-align: center;
    font-family: 'pauline';
    font-size: responsiveSizeV2(46);
  }

  a,
  button {
    z-index: 2;
    border: none;
    cursor: pointer;
    font-family: 'Itim';
    position: relative;
    background-color: #FFFFFF;
    width: responsiveSizeV2(291);
    height: responsiveSizeV2(68);
    font-size: responsiveSizeV2(31);
    margin-top: responsiveSizeV2(25);
    border-radius: responsiveSizeV2(15);
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  }

  h1 {
    z-index: 2;
    color: white;
    margin-bottom: 0;
    font-weight: 400;
    font-style: normal;
    font-family: 'marvin-round';
    font-size: responsiveSizeV2(58);
    margin-top: responsiveSizeV2(50);
  }

  h2 {
    z-index: 2;
    color: white;
    font-weight: 400;
    font-style: bold;
    font-family: 'Pauline';
    margin-top: responsiveSizeV2(10);
    font-size: responsiveSizeV2(46);
    line-height: responsiveSizeV2(41);
    margin-bottom: responsiveSizeV2(20);
  }

  .ks-awlad-more {
    margin: 0;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    font-family: 'Itim';
    box-sizing: content-box;
    box-shadow: none;
    width: responsiveSizeV2(291);
    height: responsiveSizeV2(53);
    background-color: transparent;
    font-size: responsiveSizeV2(31);
    border: responsiveSizeV2(3) solid white;
  }

  .boat {
    z-index: 1;
    position: absolute;
    right: responsiveSizeV2(10);
    width: responsiveSizeV2(564);
    height: responsiveSizeV2(376);
    bottom: responsiveSizeV2(209);
  }

  .waves {
    z-index: 1;
    position: absolute;
    width: responsiveSizeV2(1754);
    height: responsiveSizeV2(305);
    bottom: responsiveSizeV2(234);
  }

  .water {
    bottom: 0;
    width: 100%;
    position: absolute;
    background-color: #51AABC;
    height: responsiveSizeV2(250);
  }
}
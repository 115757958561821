@import '../../common.scss';

.ks-tuto-arrow {
  g[id^="tuto_arrow_"] { display: none }
  &.display-arrow-1 { g[id="tuto_arrow_1"] { display: block } }
  &.display-arrow-2 { g[id="tuto_arrow_2"] { display: block } }
  &.display-arrow-3 { g[id="tuto_arrow_3"] { display: block } }
  &.display-arrow-4 { g[id="tuto_arrow_4"] { display: block } }
  &.display-arrow-5 { g[id="tuto_arrow_5"] { display: block } }
  &.display-arrow-6 { g[id="tuto_arrow_6"] { display: block } }
  &.display-arrow-7 { g[id="tuto_arrow_7"] { display: block } }
  &.display-arrow-8 { g[id="tuto_arrow_8"] { display: block } }
}

.ks-tutoExplanation {
  position: absolute;
  text-align: center;
  background-color: black;
  z-index: 53;
  display: flex;
  align-items: center;
  @include responsive-size(left, 160);
  @include responsive-size(width, 1366);
  height: 0;
  overflow: hidden;
  transition: height 0.3s;
  -webkit-transform: translateZ(0);
  &.ks-tutoExplanation-open {
    @include responsive-size(height, 174);
  }
  color: white;
  .ks-tutoProgress {
    font-family: 'Fredoka One';    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #65b1fc;
    @include responsive-size(margin-top, 0);
    @include responsive-size(padding, 15);
    @include responsive-size(border-bottom-right-radius, 50);

    .ks-tutoProgress-title {
      @include responsive-size(font-size, 39);
    }
    .ks-tutoProgress-progress {
      @include responsive-size(font-size, 69);
    }
  }
  .ks-tutoText-btn {
    flex: 1;
    .ks-tutoText {
      font-family: 'Itim';
      line-height: 1;
      margin-bottom: 1%;
      @include responsive-size(font-size, 39);
    }
    .ks-tutoBtn {
      .mainBtn {
        background-color: #65B1FC;
        @include responsive-size(width, 198);
        @include responsive-size(height, 41);
        @include responsive-size(font-size, 26);
      }
      .mainBtn:first-child {
        margin-right: 2%;
      }
    }
  }
  .ks-iconClose-wrapper {
    height: 100%;
    display: flex;
    align-items: flex-end;
    .ks-iconClose {
      @include responsive-size(margin-bottom, 14);
      @include responsive-size(margin-right, 21);
      @include responsive-size(width, 35);
      @include responsive-size(height, 35);
    }
  }
}

.ks-tutoOverlay {
  z-index: 52;
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
}

.ks-arrowTuto {
  position: absolute;
  &, svg {
    @include responsive-size(width, 122.658);
    @include responsive-size(height, 117.463);
  }
  svg {
    animation-duration: .8s;
    animation-name: blink;
    animation-iteration-count: infinite;
  }    
  @keyframes blink {
    0%   { opacity:1; }
    40%   {opacity:0; }
    100% { opacity:1; }
  }
  z-index: 1;
}

.hp-layoutV2 {
  .ks-arrowTuto {
    &, svg {
      width: responsiveSizeV2(122.658);
      height: responsiveSizeV2(117.463)
    }
  }
}
@import "common.scss";
.select-component {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  &.rightTwo {
    margin-right: responsiveSizeV2(263);
  }
  .select-label {
    margin-right: responsiveSizeV2(25);
    font-size: responsiveSizeV2(25);
    font-family: "Avenir";
    font-weight: 900;
    color: #51aabc;
  }
  .select-button {
    cursor: pointer;
    font-size: responsiveSizeV2(22);
    font-family: "Itim";
    height: responsiveSizeV2(76);
    width: responsiveSizeV2(220);
    border-radius: responsiveSizeV2(10);
    margin: responsiveSizeV2(10);
    border: none;
    background-color: #f2f2f2;
    &.active {
      background-color: #e8d19c;
    }
  }
}

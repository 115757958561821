@function responsiveSizeMobileV2($sizeInPixel, $ratio: 1) {
  @return calc(#{$sizeInPixel} * var(--vw) / 775);
}


.hp-userAnnouncements {
  display: none;
}

@media only screen and (max-width: 768px) and (-webkit-max-device-pixel-ratio: 5) {
  .hp-homepage-notconnected {
    display: none !important;
  }

  body {
    overflow-y: auto !important;
    position: relative !important;
  }

  .hp-userAnnouncements {
    display: flex;
  }

  .hp-userAnnouncements.newsletter {
    display: none !important;
  }
}

.hp-userAnnouncements {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: fit-content;
  background-color: rgba($color: black, $alpha: 0.95);
  z-index: 100;
  align-items: center;
  flex-direction: column;

  .awladLogo {
    width: responsiveSizeMobileV2(370);
    height: responsiveSizeMobileV2(147);
    margin-top: responsiveSizeMobileV2(81);

    path {
      fill: #4CB3C4
    }
  }

  h2 {
    color: white;
    font-weight: bold;
    text-align: center;
    font-family: 'pauline';
    font-size: responsiveSizeMobileV2(46);
  }

  .importanteNote {
    position: relative;
    background-color: white;
    width: responsiveSizeMobileV2(654);
    height: responsiveSizeMobileV2(347);
    margin-top: responsiveSizeMobileV2(5);
    border-radius: responsiveSizeMobileV2(16);

    svg {
      left: 0;
      bottom: 0;
      position: absolute;
      width: responsiveSizeMobileV2(654);
      height: responsiveSizeMobileV2(347);
    }

    > div {
      display: flex;
      font-family: 'Itim';
      align-items: center;
      flex-direction: column;
      width: responsiveSizeMobileV2(356);
      height: responsiveSizeMobileV2(241);
      margin-left: responsiveSizeMobileV2(262);
      h3 {
        margin: 0;
        font-size: responsiveSizeMobileV2(38);
        line-height: responsiveSizeMobileV2(38);
        margin-top: responsiveSizeMobileV2(45);
      }
      p {
        margin: 0;
        color: #4CB3C4;
        text-align: center;
        width: responsiveSizeMobileV2(334);
        font-size: responsiveSizeMobileV2(32);
        margin-top: responsiveSizeMobileV2(20);
        line-height: responsiveSizeMobileV2(28);
      }
    }
  }

  h4 {
    color: white;
    margin-bottom: 0;
    text-align: center;
    font-family: 'Itim';
    text-transform: uppercase;
    font-size: responsiveSizeMobileV2(29);
    margin-top: responsiveSizeMobileV2(58);
  }

  .links {
    width: 100%;
    display: flex;
    justify-content: center;
    .col {
      width: 49%;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      svg {
        width: responsiveSizeMobileV2(137);
        height: responsiveSizeMobileV2(272);
      }

      p {
        color: #4CB3C4;
        text-align: center;
        font-family: 'Itim';
        text-transform: uppercase;
        font-size: responsiveSizeMobileV2(28);
      }
      &.col2 {
        #lottie {
          width: responsiveSizeMobileV2(362);
          height: responsiveSizeMobileV2(362);
        }
      }
    }
  }
}
@import '../../common.scss';

.ks-waladCards-panel {
  position: absolute;
  z-index: 30;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: #EAC676;
  width: 100%;
  height: 100%;
  .ks-bonusEnigma-closeBtn {
    top: 2%;
    right: 2%;
    position: absolute;
    z-index: 24;
    @include responsive-size(width, 87);
    @include responsive-size(height, 87);
  }
  .ks-waladCards-title {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    align-content: center;
    justify-content: center;
    svg {
      @include responsive-size(width, 387.67);
    }
    &, svg {
      @include responsive-size(height, 153.07);
    }
  }

  .ks-waladCards-section {
    text-align: center;
    @include responsive-size(margin-bottom, 31);
    .ks-waladCards-section-title {
      width: 90%;
      position: relative;
      display: flex;
      align-items: center;
      margin: auto;
      @include responsive-size(margin-bottom, 26);
      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        border-top: 3px solid #C39745;
        background: black;
        width: 100%;
        transform: translateY(-50%);
      }
      &, label {
        @include responsive-size(height, 42);
      }
      label {
        z-index: 1;
        text-transform: uppercase;
        color: white;
        background-color: #C39745;
        font-family: 'Fredoka One';
        display: flex;
        align-items: center;
        justify-content: center;
        @include responsive-size(border-radius, 21);
        @include responsive-size(font-size, 20);
        @include responsive-size(width, 274);
        @include responsive-size(margin-left, 25);
      }
    }
    .ks-waladCards-section-cards {
      display: flex;
      justify-content: flex-start;
      justify-content: center;
      align-items: center;
      width: 100%;
      .ks-waladCards-section-cards-arrowNext,
      .ks-waladCards-section-cards-arrowPrevious {
        &, svg {
          @include responsive-size(width, 29);
          @include responsive-size(height, 60);
        }
      }
      .ks-waladCards-section-cards-arrowNext {
        @include responsive-size(margin-left, 25);
      }
      .ks-waladCards-section-cards-arrowPrevious {
        @include responsive-size(margin-right, 25);
      }
      .ks-waladCards-section-cardsWrapper {
        display: flex;
        width: 86.4%;
        overflow-x: scroll;
        overflow-y: hidden;
        scrollbar-width: none; /* Firefox */
        -ms-overflow-style: none;  /* Internet Explorer 10+ */
        &::-webkit-scrollbar { /* WebKit */
          width: 0;
          height: 0;
        }
        .ks-waladCards-section-card {
          &:not(:last-child) {
            @include responsive-size(margin-right, 44);
          }
        }
      }
    }
  }
}
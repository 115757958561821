@import '../../common.scss';

.ks-certificate {
  .ks-modalContent {
    position: relative;
  }

  .ks-certificateMain {
    &, svg {
      @include responsive-size(width, 1304);
      @include responsive-size(height, 922);
    }
    .ks-certificate-id,
    .ks-certificate-userName,
    .ks-certificate-dateTime {
      position: absolute;
      @include responsive-size(font-size, 37);
      font-family: 'Itim';
    }
    .ks-certificate-id {
      @include responsive-size(font-size, 28);
      @include responsive-size(top, 265.5);
      @include responsive-size(left, 611.04);
    }
    .ks-certificate-userName {
      text-align: center;
      @include responsive-size(width, 495);
      @include responsive-size(top, 418);
      @include responsive-size(left, 425);
    }

    .ks-certificate-dateTime {
      color: #C3C3C3;
      letter-spacing: -1px;
      @include responsive-size(top, 569);
    }
    display: flex;
    justify-content: center;
    @include responsive-size(margin-top, 3.25);

  }
  .ReactModal__Overlay {
    z-index: 100;
    background-color: black !important;
    display: flex;
    justify-content: center;
  }
  .ReactModal__Content {
    inset: auto !important;
    border: none !important;
    background: none !important;
    width: fit-content;
    height: fit-content;
    @include responsive-size(top, 38, true);
    @include responsive-size(padding, 20, true);
    left: auto !important;
    right: auto !important;
    bottom: auto !important;
  }
  .ks-certificateBtn {
    display: flex;
    justify-content: center;
    color: #DDDDDD;
    margin-top: 1.5%;
    @include responsive-size(height, 100);
    > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      font-family: 'Avenir';
      @include responsive-size(font-size, 17);
      &.ks-printBtn {
        margin-right: 3%;
      }
      &.ks-dwonloadBtn {
        margin-left: 2.7%;
        svg {
          width: auto;
          @include responsive-size(height, 68.18);
        }
      }
      &.ks-printBtn,
      &.ks-shareBtn {
        @include responsive-size(margin-left, 18);
        svg {
          width: auto;
          @include responsive-size(height, 74);
        }
      }
      &.ks-shareBtn {
        div {
          @include responsive-size(margin-right, 18);
        }
      }
    }
  }
  .ks-certificate-close {
    position: fixed;
    @include responsive-size(top, 25);
    @include responsive-size(margin-left, 1320);
    height: auto;
    &, svg {
      @include responsive-size(width, 111.38);
    }
  }
}
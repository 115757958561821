@import "../../../../common.scss";

.awlad-blocked-popup {
	position: relative;
	width: responsiveSizeToV2(1020);
	height: responsiveSizeToV2(741);
	text-transform: none !important;
	&__background {
		left: 0;
		bottom: 0;
		z-index: 1;
		position: absolute;
		width: 100% !important;
		height: 100% !important;
	}
	&__main {
		z-index: 2;
		position: absolute;
		display: flex;
		align-items: center;
		flex-direction: column;
		top: responsiveSizeToV2(26);
		right: responsiveSizeToV2(34);
		width: responsiveSizeToV2(770);
		height: responsiveSizeToV2(519);
		p {
			margin: 0;
			line-height: 0.95;
			text-align: center;
			font-family: 'pauline';
			font-size: responsiveSizeToV2(41);
		}
		&--frame {
			display: flex;
			flex-direction: column;
			align-items: center;
			background-color: #FFFFFF;
			width: responsiveSizeToV2(620);
			height: responsiveSizeToV2(208);
			margin-top: responsiveSizeToV2(20);
			border-radius: responsiveSizeToV2(20);
			p {
				color: #C3C3C3;
				font-weight: 300;
				font-family: 'Avenir';
				font-size: responsiveSizeToV2(26);
				margin-bottom: responsiveSizeToV2(18);
				margin-top: responsiveSizeToV2(20);
			}
			.btn-group {
				display: flex;
				justify-content: center;
				.mainBtn {
					font-weight: 700;
					font-family: 'Avenir';
					font-size: responsiveSizeToV2(26);
					width: responsiveSizeToV2(260);
					height: responsiveSizeToV2(70);
					border-radius: responsiveSizeToV2(11);
					&.ks-boxShadow {
						color: #000;
						background-color: #FFFFFF;
					}
				}
				div {
					display: flex;
					position: relative;
					flex-direction: column;
					align-items: center;
					margin-left: responsiveSizeToV2(22);
					span {
						color: #51AABC;
						bottom: -20px;
						font-weight: 900;
						position: absolute;
						font-family: 'Avenir';
						font-size: responsiveSizeToV2(17);
					}
				}
			}
		}
	}
}
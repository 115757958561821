@import "./responsive";

@function responsiveSize($sizeInPixel, $ratio: 1) {
  $unit: vh;
  $result: $sizeInPixel / 1145 * 1 * 100 * $ratio;
  @if $ratio == 3/4 {
    $unit: vw;
  }
  @return $result + $unit;
}

@function responsiveSizeToV2($sizeInPixel) {
  @return responsiveSizeLegacyToV2($sizeInPixel);
}

@function responsiveSizeForPWA($sizeInPixel, $ratio: 1) {
  $unit: vh;
  $result: $sizeInPixel / 1145 * 1 * 100 * $ratio;
  @if $ratio == 3/4 {
    $unit: vw;
  }
  @return $result + $unit;
}
@function responsiveSizeForMobile($sizeInPixel, $ratio: 1) {
  $unit: vh;
  $result: $sizeInPixel / 1145 * 0.9 * 100 * $ratio;
  @if $ratio == 3/4 {
    $unit: vw;
  }
  @return $result + $unit;
}

@mixin responsive-size($attribute, $sizeInPixel: 0, $important: false) {
  & {
    @if $important {
      #{$attribute}: responsiveSize($sizeInPixel) !important;
    } @else {
      #{$attribute}: responsiveSize($sizeInPixel);
    }
    @media (pointer:none), (pointer:coarse) {
      @if $important {
        #{$attribute}: responsiveSizeForMobile($sizeInPixel) !important;
      } @else {
        #{$attribute}: responsiveSizeForMobile($sizeInPixel);
      }
    }
    .standalone & {
      @if $important {
        #{$attribute}: responsiveSizeForPWA($sizeInPixel) !important;
      } @else {
        #{$attribute}: responsiveSizeForPWA($sizeInPixel);
      }
    }
  }
  @media (max-aspect-ratio: 4/3) {
    & {
      @if $important {
        #{$attribute}: responsiveSize($sizeInPixel, 3/4) !important;
      } @else {
        #{$attribute}: responsiveSize($sizeInPixel, 3/4);
      }
      @media (pointer:none), (pointer:coarse) {
        @if $important {
          #{$attribute}: responsiveSizeForMobile($sizeInPixel, 3/4) !important;
        } @else {
          #{$attribute}: responsiveSizeForMobile($sizeInPixel, 3/4);
        }
      }
      .standalone & {
        @if $important {
          #{$attribute}: responsiveSizeForPWA($sizeInPixel, 3/4) !important;
        } @else {
          #{$attribute}: responsiveSizeForPWA($sizeInPixel, 3/4);
        }
      }
    }
  }

  @at-root {
    .hp-layoutV2 & {
      @if $important {
        #{$attribute}: responsiveSizeLegacyToV2($sizeInPixel) !important;
      } @else {
        #{$attribute}: responsiveSizeLegacyToV2($sizeInPixel);
      }
    }
  }
}

@function responsiveSizeLegacyToV2($sizeInPixel, $ratio: 1) {
  $unit: px;
  @return calc(#{$sizeInPixel} / 1145 * var(--mainContentHeight));
}


@function responsiveSizeV2($sizeInPixel, $ratio: 1) {
  @return calc(#{$sizeInPixel} * var(--mainContentHeight) / 1039);
}

@function responsiveSizeMobileV2($sizeInPixel, $ratio: 1) {
  @return calc(#{$sizeInPixel} * var(--mainContentWidth) / 1040);
}

@function responsiveSizeMobileNewsletter($sizeInPixel, $ratio: 1) {
  @return calc(#{$sizeInPixel} * var(--mainContentWidth) / 450);
}

@font-face {
  font-family: 'KG Blank Space Solid';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/KGBlankSpaceSolid.ttf');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/Inter-VariableFont_slnt_wght.ttf');
}

@font-face {
  font-family: 'Fredoka One';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/FredokaOne-Regular.ttf');
}

@font-face {
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 200;
  src: url('./assets/fonts/Avenir/Avenir-Light-07.ttf');
}

@font-face {
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 300;
  src: url('./assets/fonts/Avenir/Avenir-Book-01.ttf');
}

@font-face {
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/Avenir/Avenir-Medium-09.ttf');
}

@font-face {
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 700;
  src: url('./assets/fonts/Avenir/Avenir-Heavy-05.ttf');
}

@font-face {
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 900;
  src: url('./assets/fonts/Avenir/Avenir-Black-03.ttf');
}

@font-face {
  font-family: 'Minion Prod';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/Minion-Pro-Medium.ttf');
}

@font-face {
  font-family: 'Itim';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/Itim-Regular.ttf');
}

@font-face {
  font-family: 'BahijMyriadArabic-Bold';
  font-style: normal;
  font-weight: 700;
  src: url('./assets/fonts/Bahij_Myriad_Arabic-Bold.ttf');
}

@font-face {
  font-family: 'Bahij Myriad Arabic';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/Bahij_Myriad_Arabic-Regular.ttf');
}

@font-face {
  font-family: 'Fedra Sans Arabic AR';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/FedraSansArabicAR-Medium.otf');
}

@font-face {
  font-family: 'Baloo Bhaijaan 4';
  font-style: normal;
  font-weight: 500;
  src: url('./assets/fonts/BalooBhaijaan2-Medium-v4.ttf');
}

@font-face {
  font-family: 'Baloo Bhaijaan 5';
  font-style: normal;
  font-weight: 600;
  src: url('./assets/fonts/BalooBhaijaan2-SemiBold-v5.ttf');
}
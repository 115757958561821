@import "../../common.scss";

.imageText {
  display: flex;
  .imageText-text {
    color: white;
    margin-left: responsiveSizeV2(31);
    h2 {
      margin-top: 0;
      line-height: responsiveSizeV2(35);
      font-weight: bold;
      font-family: 'minion-pro';
      font-size: responsiveSizeV2(35);
      margin-bottom: responsiveSizeV2(20);
    }
    p {
      margin-top: 0;
      font-weight: 400;
      font-family: 'Avenir';
      font-size: responsiveSizeV2(22);
    }
  }
}
@import './common.scss';

@import url("https://use.typekit.net/emb7sms.css");

html,
body {
  overflow: hidden;
  position: fixed;
  margin: auto;
  height: fit-content;
  max-height: 100vh;
  left: 0;
  right: 0;
}

.arabic {
  font-family: 'Baloo Bhaijaan 5' !important;
  font-weight: 600 !important;
}

.hp-layoutV2.hp-layoutV3 .hp-leftArea {
  display: flex !important;
  justify-content: flex-start !important;
  margin-top: 0 !important;
  height: calc(var(--mainContentHeight)) !important;
  position: relative;

  .ks-phase3-chapterSelected,
  .ks-readwrite-homepage-title,
  .ks-alphabet-homepage-title {
    width: responsiveSizeV2(325);
    height: responsiveSizeV2(226);
    margin-top: responsiveSizeV2(17);
  }

  .ks-awlad-logo {
    cursor: pointer;
    margin-top: responsiveSizeLegacyToV2(100);
    width: responsiveSizeLegacyToV2(313);
    height: responsiveSizeLegacyToV2(125);
  }

  .ks-phase3-chapterSelected {
    width: responsiveSizeLegacyToV2(320);
    height: responsiveSizeLegacyToV2(220);
    margin-top: responsiveSizeV2(17);
  }
}

.ks-awlad-avatar-student {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #DDDDDD;
  width: responsiveSizeLegacyToV2(316);
  height: responsiveSizeLegacyToV2(74);

  img {
    width: responsiveSizeLegacyToV2(68);
    height: responsiveSizeLegacyToV2(70);
    border-radius: responsiveSizeLegacyToV2(15);
    border: 2px solid #DDDDDD;
  }

  span {
    flex: 1;
    font-family: 'Avenir';
    box-sizing: border-box;
    border-bottom: 2px solid #DDDDDD;
    width: responsiveSizeLegacyToV2(244);
    font-size: responsiveSizeLegacyToV2(25);
    margin-top: responsiveSizeLegacyToV2(-5);
    padding-left: responsiveSizeLegacyToV2(9);
  }
}

.overlay-challengeV2 .ReactModal__Overlay {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ks-download-btn,
.ks-homepage-btn,
.ks-revision-btn,
.ks-diploma-btn,
.ks-walad-btn {
  cursor: pointer;
  width: responsiveSizeV2(281);
  height: responsiveSizeV2(62);
  margin-top: responsiveSizeV2(25);
}

.ks-phase3-chapter1-bkg {
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
}

button.mainBtn {
  font-family: 'Fredoka One';
  background-color: #51AABC;

  &:focus {
    outline: 0;
  }

  cursor: pointer;
  @include responsive-size(border-radius, 30);
  @include responsive-size(padding-left, 16);
  @include responsive-size(padding-right, 16);
  @include responsive-size(padding-top, 6);
  @include responsive-size(padding-bottom, 6);
  border: none;
  color: white;

  &:disabled {
    opacity: 0.4;
  }
}

.disabled {
  opacity: 0.4;
}

.ks-mainFrame {
  width: fit-content;
  height: fit-content;
  margin: auto;
  overflow: hidden;
}

.ks-mainFrame {
  border: solid white;
  @include responsive-size(border-width, 4);
  @include responsive-size(border-radius, 34);

  &.ks-notAuthenticated {
    border: solid black;
  }
}


.standalone {
  .ks-mainFrame {
    border: none;
    border-width: 0;
    border-radius: 0;
  }
}

.ks-clickable {
  cursor: pointer;
}

.ks-unallowed {
  cursor: not-allowed;
  pointer-events: none;
}

.ks-leftPanel {
  width: 10.6%;
  z-index: 20;
  background-color: #1c1d1e;
  display: flex;
  align-items: center;
  flex-direction: column;
  font-family: 'Inter';
  color: white;
  @include responsive-size(padding-top, 13);

  >div {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

img.ks-avatar {
  @include responsive-size(width, 115);
  @include responsive-size(height, 115);
  @include responsive-size(border-radius, 115);
}

.ks-avatar {
  margin-left: auto;
  margin-right: auto;

  img {
    @include responsive-size(width, 115);
    @include responsive-size(height, 115);
  }

  >div {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;

    &,
    svg {
      @include responsive-size(width, 115);
      @include responsive-size(height, 115);
    }
  }
}

.ks-usnername {
  font-family: 'Inter';
  color: black;
  background-color: white;
  border: solid white;
  text-align: center;
  line-height: 1;
  @include responsive-size(width, 125);
  @include responsive-size(height, 39);
  @include responsive-size(font-size, 18);
  @include responsive-size(margin-top, 12);
  @include responsive-size(margin-bottom, 18);
  @include responsive-size(border-width, 2);
  @include responsive-size(border-radius, 30);
  display: flex;
  justify-content: center;
  align-items: center;
}

.ks-letter-cards {
  width: 100%;
  display: flex;
  position: relative;
  justify-content: space-evenly;
  @include responsive-size(height, 423);

  .ks-card {

    &>div,
    svg,
    img {
      @include responsive-size(width, 326);
      @include responsive-size(height, 420);
    }
  }

  .ks-letterCard {
    z-index: 2;
  }

  .ks-illustrationCard {
    z-index: 1;
    position: absolute;

    &.ks-illustration1 {
      transform: translateX(0);
      visibility: hidden;

      &.ks-display {
        visibility: visible;
        transition: transform 1s;
        transform: translateX(-107%);
        -webkit-transform: translateZ(0);
      }

      &.ks-display-no-transition {
        visibility: visible;
        transition: none;
        transform: translateX(-107%);
      }
    }

    &.ks-illustration2 {
      transform: translateX(0);
      visibility: hidden;

      &.ks-display {
        visibility: visible;
        transition: transform 1s;
        -webkit-transform: translateZ(0);
        transform: translateX(107%);
      }

      &.ks-display-no-transition {
        visibility: visible;
        transition: none;
        transform: translateX(107%);
      }
    }
  }
}

.ks-navigation-next {
  transform: rotate(180deg);
}

.ks-disabled,
.ks-not-selected {
  opacity: 0.3;
}

.ks-disabled {
  cursor: not-allowed;
  pointer-events: none;
}

.ks-helpBtn {
  position: absolute;
  right: 1.2%;
  bottom: 2%;
  @include responsive-size(width, 35);
  @include responsive-size(height, 35);
}

.ks-progression-flag-letter,
.ks-progression {
  position: absolute;
  bottom: -0.2%;

  width: 100%;
  @include responsive-size(height, 270);

  .ks-backwave,
  .ks-frontwave,
  .ks-boat,
  .ks-flag {
    position: absolute;

    svg {
      @include responsive-size(width, 72.29);
      @include responsive-size(height, 158.36);
    }
  }

  .ks-backwave,
  .ks-frontwave {
    svg {
      @include responsive-size(width, 1365);
      @include responsive-size(height, 77.586);
    }
  }

  .ks-backwave {
    z-index: 1;
    @include responsive-size(bottom, 25.2);
  }

  .ks-boat {
    z-index: 3;
    transition: all 1s;
    @include responsive-size(left, -15);
    @include responsive-size(left, -15);
    @include responsive-size(bottom, 50);

    &,
    svg {
      @include responsive-size(width, 323.45);
      height: auto;
    }
  }

  .ks-frontwave {
    z-index: 4;
    bottom: 0;

    @media (pointer:none),
    (pointer:coarse) {
      bottom: -0.4%;
    }
  }

  .ks-flag {
    right: 2.5%;
    bottom: 9%;
    z-index: 2;
  }
}

.ks-progression-flag-letter {
  height: 35%;
  position: absolute;
  bottom: 0;
  width: 100%;

  .ks-boat {
    left: 0;

    svg {
      @include responsive-size(width, 192.94);
      @include responsive-size(height, 165.32);
    }
  }

  .ks-flag {
    top: auto;

    svg {
      @include responsive-size(width, 127.52);
      @include responsive-size(height, 345.57);
    }

    &.ks-flag-left {
      left: 0;
      right: auto;
    }

    &.ks-flag-middle {
      left: 45.7%;
      right: auto;
    }

    &.ks-flag-right {
      right: 0;
    }

    &.ks-flag-select {
      svg {
        g[fill="#fff"] {
          fill: #51AABC;
        }

        path[id^="letter"],
        text[id^="letter"],
        path[id^="moon"],
        g[id="sun"],
        path[fill="gray"],
        path[fill="#c3c3c3"] {
          fill: white;
        }

        g[id="sun"] {
          path {
            fill: white;
          }
        }
      }
    }
  }
}

.ks-navigation-play {
  display: flex;
  align-items: center;
  justify-content: center;
  @include responsive-size(margin-top, 30);

  div[class^="ks-navigation"] svg {
    @include responsive-size(width, 68.07);
    @include responsive-size(height, 72.79);

    path {
      fill: #51AABC;
    }
  }

  div.ks-disabled-arrow {
    svg path {
      fill: #DDDDDD;
    }
  }

  .ks-play {
    @include responsive-size(margin-left, 49);
    @include responsive-size(margin-right, 49);

    svg {
      @include responsive-size(width, 90);
      @include responsive-size(height, 90);
    }
  }
}

svg {
  cursor: default;
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE10+/Edge */
  user-select: none;

  /* Standard */
  .ks-clickable & {
    cursor: pointer;
  }
}

.ks-content {
  background-color: black;
  background-size: contain;
  @include responsive-size(width, 1512.6607);
  @include responsive-size(height, 1134);
}

.ks-awlad-mention {
  bottom: 0;
  margin-top: 0;
  display: flex;
  color: #FCFCFC;
  position: absolute;
  align-items: flex-end;
  right: responsiveSizeV2(13);
  height: responsiveSizeV2(83);
  width: responsiveSizeV2(350);

  .ks-bdouin-logo {
    right: 0;
    position: absolute;
    width: responsiveSizeV2(92);
    height: responsiveSizeV2(83);
  }

  p {
    margin: 0;
    z-index: 10;
    font-family: 'Avenir';
    font-size: responsiveSizeV2(16);
    a {
      color: white;
    }
  }
}

.ks-content {
  &.hp-homePage {
    background-image: url(./assets/hoopow_bg.svg);
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .ks-auth-topBanner {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      background-color: rgba(34, 23, 49, 0.5);

      &,
      svg {
        @include responsive-size(height, 82);
      }

      svg {
        @include responsive-size(width, 230);
      }
    }

    &.hp-connected {
      .ks-auth-topBanner {
        background-color: white;

        .ks-auth-version {
          color: #68A4B1;
        }
      }

      .hp-appCards {
        display: flex;
        align-items: flex-end;

        svg {
          height: auto;
          @include responsive-size(width, 271);
        }

        >div:not(:last-child) {
          @include responsive-size(margin-right, 85);
        }

        .hp-appCard {
          display: flex;
          flex-direction: column;
          align-items: center;

          .mainBtn {
            font-weight: 900;
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: 'Avenir';
            color: #2F8495;
            background-color: white;
            @include responsive-size(border-radius, 6);
            @include responsive-size(margin-top, 24);
            @include responsive-size(font-size, 21);
            @include responsive-size(width, 204);
            @include responsive-size(height, 47);
          }
        }
      }
    }

    span.ks-auth-version {
      color: #816BA2;
      position: absolute;
      font-family: 'Avenir';
      @include responsive-size(top, 29);
      @include responsive-size(left, 260);
      @include responsive-size(font-size, 23);
    }

    &.hp-connected {
      background-image: url(./assets/hoopow-homepage-connected.svg);
    }

    &.hp-mainHomePage {
      background-image: url(./assets/hoopow-homepage.svg);

      .hp-newlettersForm {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        flex-direction: column;
        background-color: white;
        @include responsive-size(width, 784);
        @include responsive-size(height, 254);
        @include responsive-size(border-radius, 28);

        span {
          color: #51AABC;
          font-family: 'Fredoka One';
          @include responsive-size(font-size, 25);
        }

        input {
          text-align: center;
          font-family: 'Avenir';
          padding-left: 0;
          @include responsive-size(font-size, 25);
          @include responsive-size(font-size, 20);
          @include responsive-size(width, 686.86);
        }

        .mainBtn {
          font-family: 'Avenir';
          font-weight: 900;
          @include responsive-size(width, 429);
          @include responsive-size(height, 58);
          @include responsive-size(font-size, 24);
        }
      }
    }
  }
}

.ks-boxShadow {
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
}

.ks-form-group {
  display: flex;
  flex-direction: column;
  @include responsive-size(margin-bottom, 20);

  input {
    outline-style: none;
    box-shadow: none;
    border: 1px solid #C3C3C3;
    @include responsive-size(height, 72);
    @include responsive-size(margin-top, 5);
    @include responsive-size(padding-left, 31);
    @include responsive-size(border-radius, 10);

    &:focus {
      border: 1px solid #51AABC;
    }
  }
}

amplify-authenticator {
  --border-radius: 28px;
  --amplify-primary-color: #51AABC;

  amplify-button {
    background-color: #51AABC;
  }

  section.section {
    @include responsive-size(border-radius, 28);
  }
}

.ks-challenge {
  .ReactModal__Content .ks-modalBtn {
    @include responsive-size(top, 605);
    @include responsive-size(right, 261);
  }
}

.ks-treasure {
  .ReactModal__Content .ks-modalBtn {
    @include responsive-size(top, 287);
    @include responsive-size(left, 0);
  }
}

.ks-background {
  position: absolute;
  width: 100.5%;
  height: 100.5%;
  z-index: 0;
  top: 0;
  left: 0;

  img,
  svg {
    width: 100%;
    height: 100%;
  }
}

.ks-chapters {
  display: flex;
  width: 100%;
  justify-content: center;
  @include responsive-size(margin-top, 35);

  >.ks-chapter-card~.ks-chapter-card {
    @include responsive-size(margin-left, 40);
  }
}

.ks-hoopow-logo {
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  overflow: hidden;
  @include responsive-size(border-bottom-right-radius, 46);

  &,
  svg {
    @include responsive-size(width, 122);
    @include responsive-size(height, 85);
  }
}
@import '../../../common.scss';

.hp-layoutV2 {
  .ks-session-lesson-numerotation {
    margin-top: responsiveSizeV2(34);
    .ks-session-lesson-numerotation-item {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      border: responsiveSizeV2(2) solid white;
      width: responsiveSizeV2(281);
      height: responsiveSizeV2(62);
      border-radius: responsiveSizeV2(62);
      font-family: 'Fredoka One';
      font-size: responsiveSizeV2(24);
      .ks-session-lesson-numerotation-arrow,
      .ks-session-lesson-numerotation-done {
        display: none;
      }
      &.ks-lesson-completed {
        cursor: pointer;
        background-color: #5DBAA8;
        .ks-session-lesson-numerotation-done {
          display: block;
          position: absolute;
          right: responsiveSizeV2(11);
          width: responsiveSizeV2(45);
          height: responsiveSizeV2(45);
        }
      }
      &.ks-lesson-unlocked {
        cursor: pointer;
      }
      &.newUnlocked {
        cursor: pointer;
        background-color: #F07C6D;
        animation-duration: .8s;
        animation-name: blink;
        animation-iteration-count: infinite;
      }
      @keyframes blink {
        0%   { opacity:1; }
        40%   {opacity:0; }
        100% { opacity:1; }
      }
      &.ks-lesson-selected {
        color: black;
        background-color: white;
        .ks-session-lesson-numerotation-arrow {
          display: block;
          position: absolute;
          transform: rotate(180deg);
          left: responsiveSizeV2(51);
          path { fill: black }
          width: responsiveSizeV2(21);
          height: responsiveSizeV2(33);
        }
      }
      &.ks-lesson-locked {
        opacity: 0.3;
        .ks-session-lesson-numerotation-padlock {
          display: block;
          position: absolute;
          left: responsiveSizeV2(32);
          width: responsiveSizeV2(26);
          height: responsiveSizeV2(34);
          path {fill: white;}
        }
      }
      &:not(:first-child) {
        margin-top: responsiveSizeV2(21);
      }
    }
  }
}
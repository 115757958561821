
@function responsiveSizeToV2($sizeInPixel, $ratio: 1) {
  $unit: px;
  @return calc(#{$sizeInPixel} / 1038 * var(--mainContentHeight));
}

.hp-layoutV3 #mainContentScreen.ks-content .ks-avatar-selector {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  background-color: #51AABC;
  width: responsiveSizeToV2(504);
  height: responsiveSizeToV2(916);
  border-radius: responsiveSizeToV2(20);
  &__title {
    color: white;
    font-weight: 700;
    font-family: 'Avenir';
    text-transform: none;
    font-size: responsiveSizeToV2(29);
    margin-top: responsiveSizeToV2(34);
    margin-bottom: responsiveSizeToV2(12);
  }
  &__gender {
    margin-bottom: responsiveSizeToV2(17);
    svg {
      cursor: pointer;
      width: responsiveSizeToV2(90);
      height: responsiveSizeToV2(90);
      &:not(:last-child) {
        margin-right: responsiveSizeToV2(16);
      }
      &.selected {
        g rect {
          fill: white;
          stroke: white;
        }
        cursor: default;
      }
    }
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
    width: responsiveSizeToV2(460);
    height: responsiveSizeToV2(716);  
    overflow-x: hidden;
    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
    .loader {
      width: 100%;
      display: flex;
      justify-content: center;
    }
    &-item {
      display: flex;
      align-items: center;
      justify-content: center;
      width: responsiveSizeToV2(105);
      height: responsiveSizeToV2(105);
      margin-bottom: responsiveSizeToV2(24);
      &:not(:nth-child(4n)) {
        margin-right: responsiveSizeToV2(13);
      }
      svg, img {
        width: responsiveSizeToV2(105);
        height: responsiveSizeToV2(105);
        border-radius: responsiveSizeToV2(105);
        opacity: 0.3;
        cursor: pointer;
      }

      &.selected {
        svg, img {
          opacity: 1;
          cursor: default;
        }
        &:hover {
          z-index: 10;
          img {
            top: responsiveSizeToV2(260);
            left: responsiveSizeToV2(142);
            position: absolute;
            transform: scale(3);
          }
        }
      }
    }
  }
}
@import '../../common.scss';

.ks-sessionLayout {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  .ks-mainPanel {
    overflow: hidden;
    border-radius: responsiveSizeLegacyToV2(18);
  }
  .ks-leftPanel {
    z-index: 53;
    .ks-targetTitle {
      font-family: 'Fredoka One';
      @include responsive-size(font-size, 20);
      @include responsive-size(margin-bottom, 8);
    }
    .ks-targetIcon {
      @include responsive-size(margin-top, 17);
    }
    .ks-targetIcon svg {
      @include responsive-size(width, 115);
      @include responsive-size(height, 115);
    }
    .ks-counter {
      .ks-minutesSeconds {
        border-top: solid #707070; 
        border-bottom: solid #707070; 
        @include responsive-size(border-width, 3);
        display: flex;
        align-items: center;
        justify-content: center;
        &.ks-timerFinished {
          border-top: solid #EAC676; 
          border-bottom: solid #EAC676; 
          color: #EAC676;
        }
      }
      &, .ks-minutesSeconds {
        @include responsive-size(height, 67);
        @include responsive-size(width, 122);
      }
      @include responsive-size(font-size, 44);
    }
    .ks-showTutoBtn {
      flex: 1; 
      justify-content: flex-end;
      align-items: center;
      svg {
        @include responsive-size(margin-bottom, 20);
        @include responsive-size(height, 121.4);
        @include responsive-size(width, 126.13);
      }
      &.ks-tutoOpen svg {
        g[stroke="gray"] {
          fill: #65B1FC;
          stroke: #FFFFFF;
        }
      }
    }
  }
  .ks-mainPanel {
    width: 89.4%;
    position: relative;
    background-color: black;
    .ks-session-bg {
      position: absolute;
      &, svg {
        width: 100%;
        height: 100%;
      }
      bottom: -2%;
    }
    .ks-help-panel {
      transition: opacity 1s, z-index 0s ease 1s;
      opacity: 0;
      z-index: 0;
      width: 36.5%;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
      background-color: black;
      position: absolute;
      height: 100%;
      right: 0;
      color: white;
      box-sizing: border-box;
      padding: 0 3.5%;
      font-family: 'Inter';
      @include responsive-size(font-size, 27);
      text-align: center;
      &.show {
        transition: opacity 1s, z-index 0s;
        z-index: 10;
        opacity: 0.9;
        pointer-events:none;
      }
      .ks-help-closeBtn {
        position: absolute;
        cursor: pointer;
        pointer-events: all;
        @include responsive-size(top, 19);
        @include responsive-size(right, 16);
        @include responsive-size(height, 35);
        @include responsive-size(width, 35);
      }
      .ks-help-title {
        font-weight: bold;
        margin-top: 17%;
        margin-bottom: 9%;
      }
      .ks-help-text {
        color: #DDDDDD;
        @include responsive-size(line-height, 33);
      }
      .ks-help-exit {
        text-decoration: underline;
        margin-top: 30%;
        pointer-events: auto;
        cursor: pointer;
        color: #C3C3C3
      }
    }
    .ks-session-top-navigation {
      position: relative;
      width: 98%;
      height: 7.07%;
      background-color: #C39745;
      display: flex;
      align-items: center;
      justify-content: center;
      .ks-session-title {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        left: 8%;
        background-color: black;
        color: white;
        font-family: 'Fredoka One';
        text-transform: uppercase;
        @include responsive-size(border-radius, 30);
        @include responsive-size(font-size, 24);
        @include responsive-size(letter-spacing, 2);
        @include responsive-size(width, 228);
        @include responsive-size(height, 51);
      }
      .ks-interface-number {
        font-family: 'Fredoka One';
        justify-content: center;
        align-items: center;
        display: flex;
        background-color: white;
        @include responsive-size(margin-left, 11.5);
        @include responsive-size(margin-right, 11.5);
        @include responsive-size(font-size, 32);
        @include responsive-size(height, 50);
        @include responsive-size(width, 50);
        @include responsive-size(border-radius, 50);
        svg {
          @include responsive-size(height, 33.63);
          @include responsive-size(width, 25.86);
          @include responsive-size(margin-bottom, 3);
          @include responsive-size(margin-left, 1);
        }
      }

      .ks-interface-number {
        background-color:   #9F721E;
        color: #C39745;
        border: solid  #9F721E;
        svg {
          fill:   #C39745;
        }
      }
      .ks-interface-selected {
        &.ks-interface-number {
          background-color: white;
          color:#9F721E;
          border: solid#9F721E;
          svg {
            fill: #9F721E;
          }
        }
      }
      .ks-interface-unlocked {
        &.ks-interface-number {
          background-color: #5A9F6D;
          color:white;
          border: solid#5A9F6D;
          svg {
            fill: #5A9F6D;
          }
        }
      }
    }

    .ks-arrowNewInterface {
      z-index: 10;
      transform: rotate(180deg);
      position: absolute;
      animation-duration: .8s;
      animation-name: blink;
      animation-iteration-count: infinite;
      @include responsive-size(top, 30, 1);
      @include responsive-size(margin-left, -100, 1);
      fill: black !important;
      &, svg {
        @include responsive-size(height, 150, 1);
        @include responsive-size(width, 150, 1);
        width: auto;
      }
      @keyframes blink {
        0%   { opacity:1; }
        40%   {opacity:0; }
        100% { opacity:1; }
      }
      &.ks-arrowNewInterfaceLast {
        @include responsive-size(margin-left, 0, 1);
        @include responsive-size(right, 362, 1);
      }
    }
    .ks-mainScreen {
      position: relative;
      height: 93.3%;
      &.ks-full-screen {
        height: 100%;
      }
      .ks-navigation {
        display: flex;
        justify-content: center;
        &,
        div[class^="ks-navigation"] svg {
          @include responsive-size(height, 72.79);
        }
  
        div[class^="ks-navigation"] svg {
          @include responsive-size(width, 68.07);
          path {
            fill: #51AABC;
          }
        }
        .ks-navigation-back {
          @include responsive-size(margin-right, 34);
        }
        div.ks-disabled-arrow {
          svg path {
            fill: #C39745;
          }
        }

        div.ks-disabled-arrow-grey {
          svg path {
            fill: #DDDDDD;
          }
        }
      }
    }
  }
  &.exercise {
    .ks-leftPanel {
      .ks-targetIcon {
        svg circle,
        svg > g > path[fill="#eac676"] {
          fill: #2F8495;
        }
      }
    }
    .ks-mainPanel {
      svg {
        path#FOND {
          fill: #4AA5B7;
        }
        path[fill="#e6c06c"] {
          fill: #51AABC;
        }
      }
      .ks-session-top-navigation {
        background-color: #2F8495;
        
        .ks-interface-number {
          background-color:  #1A5560;
          color: #2F8495;
          border: solid  #1A5560;
          svg {
            fill:  #2F8495;
          }
        }
        .ks-interface-selected {
          &.ks-interface-number {
            background-color: white;
            color: #1A5560;
            border: solid #1A5560;
            svg {
              fill: #1A5560;
            }
          }
        }
      }
    }
  }
  
  .ks-progression-balloon {
    display: flex;
    align-items: flex-end;
    position: relative;
    margin-left: 3%;
    margin-right: 3%;
    margin-bottom: 1.2%;
    .ks-balloon {
      transition: all 1s;
      svg {
        @include responsive-size(width, 204.49);
        @include responsive-size(height, 284.88);
      }
    }
    .ks-balloon-progress-bar {
      svg {
        @include responsive-size(width, 58.457);
        @include responsive-size(height, 1030.18);
        rect[id="progress_bar_firstRect"],
        rect[id="progress_bar_secondRect"] {
          transition: all 1s;
        }
      }
    }
  }
  &.revision {
    .ks-leftPanel {
      .ks-targetIcon {
        svg circle,
        svg > g > path[fill="#eac676"] {
          fill: #8CA7A2;
        }
      }
    }
    .ks-mainPanel {
      svg {
        path#FOND {
          fill:#53B2A0;
        }
        path[fill="#e6c06c"] {
          fill: #5DBAA8;
        }
      }
      .ks-session-top-navigation {
        background-color: #3F9080;
        .ks-interface-number {
          background-color: #297666;
          color: #3F9080;
          border: solid #297666;
          svg {
            fill: #3F9080;
          }
        }
        .ks-interface-selected {
          &.ks-interface-number {
            background-color:white;
            color: #297666;
            border: solid #297666;
            svg {
              fill: #297666;
            }
          }
        }
      }
    }
  }

  &.assessment {
    .ks-leftPanel {
      .ks-targetIcon {
        svg circle,
        svg > g > path[fill="#eac676"] {
          fill: #C39745;
        }
      }
    }

    .ks-balloon-progress-bar {
      @include responsive-size(margin-top, 15);
      svg {
        @include responsive-size(width, 58.457);
        @include responsive-size(height, 1030.18);
      }
    }
  }
  &.LetterWrittenInExpression,
  &.RecogniseLetterKeyboard.exercise,
  &.WriteName,
  &.LetterWrittenInName {
    .ks-leftPanel {
      .ks-targetIcon {
        svg circle,
        svg > g > path[fill="#eac676"] {
          fill: #EEBF82;
        }
      }
    }

    .ks-session-top-navigation {
      background-color: #174365;
      
      .ks-interface-number {
        background-color:  white;
        color: #316894;
        border: solid  #316894;
        svg {
          fill:  #316894;
        }
      }
      .ks-interface-selected {
        &.ks-interface-number {
          background-color: #EEBF82;
          color: #316894;
          border: solid #316894;
          svg {
            fill: #316894;
          }
        }
      }
    }
    .ks-topNavigation .ks-iconExit path[fill="#c39745"] {
      fill: #EEBF82;
    }
  }
  &.RecogniseLetterKeyboard.exercise {
    .ks-leftPanel {
      .ks-targetIcon {
        svg circle,
        svg > g > path[fill="#eac676"] {
          fill: #6DAABD;
        }
      }
    }
  }

  &.WriteExpression,
  &.LetterWrittenInExpression {
    .ks-leftPanel {
      .ks-targetIcon {
        svg circle,
        svg > g > path[fill="#eac676"] {
          fill: #A57577;
        }
      }
    }

    .ks-session-top-navigation {
      background-color: #3C1948;
      
      .ks-interface-number {
        background-color:  white;
        color: #683F66;
        border: solid  #683F66;
        svg {
          fill:  #683F66;
        }
      }
      .ks-interface-selected {
        &.ks-interface-number {
          background-color: #EEBF82;
          color: #683F66;
          border: solid #683F66;
          svg {
            fill: #683F66;
          }
        }
      }
    }
    .ks-topNavigation .ks-iconExit path[fill="#c39745"] {
      fill: #EEBF82;
    }
  }
  &.RecogniseLetterKeyboard.exercise {
    .ks-leftPanel {
      .ks-targetIcon {
        svg circle,
        svg > g > path[fill="#eac676"] {
          fill: #6DAABD;
        }
      }
    }
  }
}

svg[class^="ks-chevron-"] {
  @include responsive-size(width, 37.07);
  @include responsive-size(height, 21.2);
}

.ks-chevron-top {
  transform: rotate(0deg);
}

.ks-chevron-bottom {
  transform: rotate(180deg);
}

.assessment.ks-sessionLayout .ks-mainPanel .ks-session-bg {
  bottom: -4%;
}
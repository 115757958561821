@import '../../common.scss';

.mobile-ks-contactus {
  min-height: 100vh;
  position: relative;
  background: linear-gradient(0deg, #E6C073, #EDCB74);
  
  &-container {
    padding: 2rem 2rem 0;
  }
  
  &-illustration {
    position: relative;
    z-index: 0;
    margin-top: -400px;
  }

  .ks-awlad-logo {
    width: 30%;
    min-width: 150px;
  }

  .mobile-ks-contactus-form {
    position: relative;
    z-index: 1;
    color: black;
    font-family: 'Avenir';
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    background: #fff;
    padding: 0 2rem 2rem;
    border-radius: 18px;

    label {
      color: #51AABC;
      font-weight: 900;
      font-size: 1.5rem;
      margin-bottom: .5rem;

      span {
        font-weight: 400;
      }
    }

    textarea,
    input {
      font-family: 'Avenir';
      margin-bottom: 1rem;
      height: 66px;
      border: 4px solid #51AABC;
      border-radius: 11px;
      padding-left: 2rem;
      font-size: 1.5rem;
    }

    textarea {
      height: 320px;
      padding-top: 1rem;
    }

    .messageLottie {
      position: absolute;
      z-index: 12;
      bottom: 260px;
      left: 228px;
      width: 253px;
      height: 253px;

      .hide {
        position: absolute;
        opacity: 0;
      }

      .display {
        opacity: 1;
        transition: 1s opacity ease-in;
      }
    }

    .mobile-ks-contactus-btn-mention {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 1.5rem;

      button {
        cursor: pointer;
        border-radius: 4px;
        width: fit-content;
        display: flex;
        height: 51px;
        align-items: center;
        justify-content: center;
        color: white;
        background-color: #51AABC;
        font-family: 'Avenir';
        font-weight: 800;
        font-size: 29px;
        border: none;
        padding: .5rem 1rem;

        &[disabled] {
          cursor: default;
          opacity: 0.4;
        }
      }

      p {
        color: #4CB3C4;
        font-size: 22px;
        font-weight: 300;
        text-align: center;
        margin-top: 37px;
      }
    }
  }
}
@import "common.scss";

.audio-recorder-container {
  display: flex;
  width: responsiveSizeV2(469);
  justify-content: space-between;
  &.corrected {
    width: responsiveSizeV2(429);
    justify-content: space-evenly;
  }
  svg {
    cursor: pointer;
    height: responsiveSizeV2(138);
    width: responsiveSizeV2(138);
  }
}

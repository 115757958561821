@import "common.scss";

.notcorrected-wrapper {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .container {
    margin-top: responsiveSizeV2(-140);
    margin-bottom: responsiveSizeV2(20);

    background-color: white;
    text-align: center;
    border: responsiveSizeV2(2) solid #dddddd;
    border-radius: responsiveSizeV2(34);
    box-shadow: 0px responsiveSizeV2(3) responsiveSizeV2(6) rgba(0, 0, 0, 0.16);

    height: responsiveSizeV2(527);
    width: responsiveSizeV2(469);
    &.loading {
      align-items: center;
      display: flex;
      justify-content: center;
    }
    .letter {
      width: 100%;
      height: 100%;
      svg {
        height: inherit;
        width: 100%;
      }
    }
  }
}

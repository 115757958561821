@import "common.scss";

.hp-layoutV2.ks-phase3 {
  a {
    text-decoration: none;
  }

  .hp-leftArea {
    // margin-top: responsiveSizeV2(122);
    display: grid;
    grid-template-rows: 1fr auto 0.5fr 1fr;
    justify-content: center;
    justify-items: center;
    align-items: center;

    .ks-phase3-chapterSelected {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      flex-direction: column;
      width: responsiveSizeV2(325);
      height: responsiveSizeV2(226);
      border-radius: responsiveSizeV2(20);
      background-color: #1a5560;
      .ks-phase3-chapterTitle-arabic {
        &,
        svg {
          width: responsiveSizeV2(108);
          height: responsiveSizeV2(32);
        }
      }
      .ks-phase3-chapterTitle-latin {
        &,
        svg {
          width: responsiveSizeV2(186);
          height: responsiveSizeV2(25);
        }
      }
      .phase_icon {
        &,
        svg {
          width: responsiveSizeV2(82);
          height: responsiveSizeV2(81);
        }
      }
    }
    .audio-section {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: white;
      width: responsiveSizeV2(325);
      height: responsiveSizeV2(226);
      border-radius: responsiveSizeV2(20);
      color: #1a5560;
      .audios {
        font-size: responsiveSizeV2(30);
        font-family: "Fredoka One";
      }
      .jechange {
        font-family: "Pauline";
        font-size: responsiveSizeV2(38);
      }
      svg {
        width: responsiveSizeV2(224);
        height: responsiveSizeV2(51);
      }
      svg.phase_icon {
        width: responsiveSizeV2(41);
        height: responsiveSizeV2(65);
      }
    }
    .etapes {
      font-family: "Inter";
      top: responsiveSizeV2(250);
      padding-bottom: responsiveSizeV2(35);
      height: responsiveSizeV2(355);
      flex-direction: column;
      align-items: center;
      -ms-overflow-style: none; /* for Internet Explorer, Edge */
      scrollbar-width: none; /* for Firefox */
      overflow-y: scroll;
      &::-webkit-scrollbar {
        display: none; /* for Chrome, Safari, and Opera */
      }
    }
    .list-arrows {
      svg {
        cursor: pointer;
        height: responsiveSizeV2(18.83);
        width: responsiveSizeV2(29);
      }
    }
    .home {
      font-family: "Fredoka One";
      // margin-top: responsiveSizeV2(50);
      svg {
        margin-right: responsiveSizeV2(25.4);
      }
    }
    .alphabets {
      background-color: #ffffff;
      border: responsiveSizeV2(3) #2f8495 solid;
      color: #2f8495;
      &.allSent {
        border: responsiveSizeV2(3) #2f8495 solid;
        background-color: #51aabc;
        color: white;
        svg {
          width: responsiveSizeV2(46.14);
          height: responsiveSizeV2(57.73);
        }
      }
    }
    .game-section {
      display: flex;
      justify-self: center;
      position: relative;
      bottom: responsiveSizeV2(10);
      flex-direction: column;
      justify-content: center;
      font-family: "Fredoka One";
      align-items: center;
      background: linear-gradient(
        180deg,
        rgba(194, 122, 221, 1) 0%,
        rgba(144, 144, 206, 1) 100%
      );
      width: responsiveSizeV2(325);
      height: responsiveSizeV2(115);
      border-radius: responsiveSizeV2(20);
      color: white;
      span {
        font-size: responsiveSizeV2(28);
      }
      svg.phase_icon {
        width: responsiveSizeV2(69);
        height: responsiveSizeV2(78);
      }
    }
  }
}

.portrait {
  div.ks-phase3.hp-layoutV2 {
    .portrait_hidden {
      display: none !important;
    }
    flex-direction: column;
    .hp-leftArea {
      display: none;
    }
    #mainContentScreen.ks-content {
      height: fit-content;
      margin-top: 0;
    }
  }
}

@import '../../common.scss';

  .hp-layoutV2 {
    display: flex;
    align-items: center;
    @at-root #kalimatSchool {
      height: var(--vmin);
      .ks-mainFrame {
        border: none;
        border-radius: 0;
        .ks-blackLine {
          display: none;
        }
        .ks-logo-chapters {
          height: 100%;
        }
      }
    }
  }
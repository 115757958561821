@import "common.scss";
.dropdown-component {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: responsiveSizeV2(350);
  &.niveau {
    margin-right: responsiveSizeV2(268);
  }

  .dropdown-label {
    margin-right: responsiveSizeV2(25);
    font-size: responsiveSizeV2(25);
    font-family: "Avenir";
    font-weight: 900;
    color: #51aabc;
  }
  .dropdown-button {
    height: responsiveSizeV2(76);
    width: responsiveSizeV2(462);
    border-radius: responsiveSizeV2(10);
    margin: responsiveSizeV2(10);
    background-color: #f2f2f2;
    select {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      background-color: transparent;
      cursor: pointer;
      display: flex;
      font-size: responsiveSizeV2(26);
      font-family: "Itim";
      border: none;
      border-radius: responsiveSizeV2(10);
      width: inherit;
      height: inherit;
      text-align: center !important;
      ::-ms-expand {
        display: none;
      }
      option {
        text-align: center;
      }
    }
    svg {
      width: responsiveSizeV2(39);
      height: responsiveSizeV2(29);
      z-index: 1;
      position: relative;
      left: responsiveSizeV2(410);
      bottom: responsiveSizeV2(50);
      display: block;
      padding: 0;
    }
    &.active {
      select {
        background-color: #e8d19c;
        option {
          background-color: #f2f2f2;
        }
      }
    }
  }
}

@import '../../common.scss';

.ks-btnEnabledEvenWhenSoundOn {
  z-index: 56;
  &.ks-tutoOpen {
    z-index: 50;
  }
}

.ks-blockAllActions {
  width: 100%;
  height: 100%;
  z-index: 55;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba($color: white, $alpha: 0);
}

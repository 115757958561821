@import "../../../../common.scss";

.section2 {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  h2 {
    width: responsiveSizeV2(502);
    color: white;
    font-family: 'Avenir';
    font-size: responsiveSizeV2(23);
    font-weight: 300;
    text-align: center;
    margin-top: responsiveSizeV2(52);
    line-height: responsiveSizeV2(30);
  }

  .vimeoVideo {
    margin-bottom: responsiveSizeV2(94);
    position: relative;
    height: responsiveSizeV2(535);
    width: responsiveSizeV2(950);

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

}
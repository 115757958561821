@import '../../common.scss';


@function responsiveSizeV3($sizeInPixel, $ratio: 1) {
  $unit: px;
  @return calc(#{$sizeInPixel} / 1038 * var(--mainContentHeight));
}

.hp-layoutV2 .hp-blockedModal-popup.extra-step.SIGNIN {
  .hp-blockedModal-popup__wrapper {

    &,
    amplify-authenticator {
      width: responsiveSizeV3(660);
      height: responsiveSizeV3(737);
    }

    amplify-authenticator {
      display: flex;
      align-items: center;
    }

    .hp-blockedModal-popup__close {
      top: responsiveSizeV3(10);
      right: responsiveSizeV3(10);
    }
  }
}


.hp-layoutV2.ks-homepageV2 #mainContentScreen.ks-content .ks-login {
  width: responsiveSizeV3(660);
  height: responsiveSizeV3(737);
  background-color: white;
  border-radius: responsiveSizeV3(50);
  display: flex;
  flex-direction: column;
  align-items: center;

  svg {
    width: responsiveSizeV3(239);
    height: responsiveSizeV3(95);
    margin-top: responsiveSizeV3(28);

    path {
      fill: #51AABC;
    }
  }

  h1 {
    margin-top: 0;
    font-weight: 400;
    margin-bottom: 0;
    color: #51AABC;
    font-family: 'Avenir';
    font-size: responsiveSizeV3(27);
  }

  .ks-form-group {
    width: 75%;
    margin-bottom: 0;
    margin-top: responsiveSizeV3(42);

    label {
      margin-top: 0;
      color: black;
      font-weight: 700;
      text-align: center;
      font-family: 'Avenir';
      font-size: responsiveSizeV3(29);
    }

    input {
      padding: 0;
      border: none;
      font-weight: 200;
      text-align: center;
      font-family: 'Avenir';
      height: responsiveSizeV3(61);
      line-height: responsiveSizeV3(28);
      font-size: responsiveSizeV3(30);
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    }
  }

  button.ks-forgetBtn {
    border: none;
    font-weight: 700;
    font-family: 'Avenir';
    text-decoration: underline;
    background-color: transparent;
    font-size: responsiveSizeV3(21);
    margin-top: responsiveSizeV3(32);
  }

  button.submitBtn {
    border: none;
    color: white;
    cursor: pointer;
    position: relative;
    font-family: 'Fredoka One';
    background-color: #51AABC;
    width: responsiveSizeV3(287);
    height: responsiveSizeV3(62);
    font-size: responsiveSizeV3(35);
    margin-top: responsiveSizeV3(83);
    border-radius: responsiveSizeV3(83);

    &:disabled {
      opacity: 0.4;
      cursor: default;
    }

    .loader {
      top: 0;
      display: flex;
      position: absolute;
      justify-content: flex-start;

      >div {
        margin-left: responsiveSizeV3(15);
      }
    }
  }
}
@import "common.scss";

.modal-wrapper {
  position: absolute;
  display: flex;
  text-align: center;
  align-items: center;
  max-width: responsiveSizeV2(1030);
  height: responsiveSizeV2(520);
  z-index: 3000;
  left: responsiveSizeV2(177);
  top: responsiveSizeV2(223);
  box-shadow: 0 responsiveSizeV2(3) responsiveSizeV2(6) rgba(0, 0, 0, 0.16);

  background: white;
  border-radius: responsiveSizeV2(33);
  &.allSent {
    padding: 0 0 0 responsiveSizeV2(192);
    max-width: responsiveSizeV2(911);
    height: responsiveSizeV2(473);
  }
  &.welcome-modal {
    height: responsiveSizeV2(580);
  }
  &.creation-modal {
    height: responsiveSizeV2(756);
    top: responsiveSizeV2(86);
    left: responsiveSizeV2(165);
    max-width: responsiveSizeV2(1236);
  }
}
.modal-note {
  bottom: responsiveSizeV2(43);
  text-align: center;
  max-width: responsiveSizeV2(1030);
  position: absolute;
  left: responsiveSizeV2(245);
  z-index: 2222;
  p {
    font-family: "Avenir";
    font-size: responsiveSizeV2(25);
    color: white;
  }
}

.modal-overlay {
  z-index: 2000;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(47, 132, 149, 0.7); //#2f8495
}

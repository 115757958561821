@import '../../common.scss';

.hp-layoutV2 {
  .ks-session-notion-popup {
    top: 0;
    left: var(--leftAreaMinWidth);
    position: absolute;
    background-color: rgba($color: #51AABC, $alpha: 0.9);
    width: var(--mainContentWidth);
    height: var(--mainContentHeight);
    z-index: 10;
    border-radius: responsiveSizeV2(16);
    display: flex;
    justify-content: center;
    align-items: center;
    .ks-session-notion-frame {
      background-color: #FFFFFF;
      width: responsiveSizeV2(1006);
      min-height: responsiveSizeV2(539);
      border-radius: responsiveSizeV2(33);
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .ks-session-notion-image {
        &, svg {
          width: responsiveSizeV2(387);
          height: responsiveSizeV2(153);
          g[stroke="#ddd"] { stroke: none;}
          path {fill: #51AABC;}
        }
      }

      &.ks-session-notion-frame-full {
        position: relative;
        background-color: transparent;
        .ks-session-notion-image-full {
          &, svg {
            width: responsiveSizeV2(1006);
            height: responsiveSizeV2(539);
          }
        }
        .mainBtn {
          position: absolute;
          bottom: 0;
        }
      }
      .ks-session-notion-text {
        line-height: 1.3;
        text-align: center;
        font-family: 'Lato';
        font-size: responsiveSizeV2(35);
        p {margin: 0;}
      }
      .mainBtn {
        font-family: 'Fredoka One';
        font-size: responsiveSizeV2(26);
        width: responsiveSizeV2(259);
        height: responsiveSizeV2(63);
        margin-top: responsiveSizeV2(43);
        margin-bottom: responsiveSizeV2(60);
        border-radius: responsiveSizeV2(63);
      }
    }
  }
  .ks-session-left-area {
    height: 100%;
    display: flex;
    position: relative;
    align-items: center;
    flex-direction: column;
    .ks-session-left-area-black-overlay {
      display: none;
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: rgba($color: #000000, $alpha: 0.5);
      &.tutoOpen {
        display: block;
        z-index: 8;
      }
    }
    .ks-session-title {
      &, svg {
        width: responsiveSizeV2(344);
        height: responsiveSizeV2(85);
      }
      margin-top: responsiveSizeV2(34);
    }
    .ks-number-pad {
      margin-top: responsiveSizeV2(34);
    }
    .ks-tuto-control {
      margin-top: responsiveSizeV2(22);
      z-index: 60;
    }
    .ks-timer-display {
      margin-top: responsiveSizeV2(25);
    }
    .ks-session-left-bottom-btn {
      width: 100%;
      display: flex;
      position: absolute;
      align-items: center;
      flex-direction: column;
      bottom: responsiveSizeV2(22);
      .ks-session-help-btn {
        z-index: 8;
        cursor: pointer;
        width: responsiveSizeV2(281);
        height: responsiveSizeV2(62);
        margin-top: responsiveSizeV2(22);
        &.tutoOpen {
          fill: #F07C6D;
        }
      }
      .ks-session-back-btn {
        cursor: pointer;
        width: responsiveSizeV2(281);
        height: responsiveSizeV2(62);
        margin-top: responsiveSizeV2(22);
      }
    }
  }
}
@import "../../common.scss";

.testimony {
  text-align: center;
  width: responsiveSizeV2(517);
  height: responsiveSizeV2(304);
  border-radius: responsiveSizeV2(9);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  background-color: white;
  color: black;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  justify-content: center;
  font-family: 'Avenir';
  p {
    font-size: responsiveSizeV2(25);
    line-height: responsiveSizeV2(30);
    width: responsiveSizeV2(480);
  }
  div {
    position: absolute;
    bottom: responsiveSizeV2(21);
    color: #A8BFC1;
    font-size: responsiveSizeV2(20);
  }

}
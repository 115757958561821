@import '../../../common.scss';

.ks-IndexOverview {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 90;
  display: flex;
  position: absolute;
  flex-direction: column;
  align-items: center;
  background-color: #12414A;
  .ks-IndexOverview-search {
    height: 10%;
    display: flex;
    position: relative;
    align-items: center;
    svg {
      position: absolute;
      left: responsiveSizeLegacyToV2(27);
      width: responsiveSizeLegacyToV2(39);
      height: responsiveSizeLegacyToV2(39);
    }
    input {
      border:0;outline:0;
      outline: none;
      background-color: #E5E5E5;
      padding-left: responsiveSizeLegacyToV2(78);
    }
    input:focus {outline:none!important;}
    input {
      width: responsiveSizeLegacyToV2(525);
      height: responsiveSizeLegacyToV2(52);
      border-radius: responsiveSizeLegacyToV2(52);
    }
  }
  .ks-IndexOverview-results {
    max-height: 90%;
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: responsiveSizeLegacyToV2(30);
    overflow-x: hidden;
    overflow-y: auto;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    &::-webkit-scrollbar { /* WebKit */
      width: 0;
      height: 0;
    }
    .ks-IndexOverview-results-frame {
      display: flex;
      text-align: center;
      align-items: center;
      flex-direction: column;
      background-color: white;
      width: responsiveSizeLegacyToV2(680);
      height: responsiveSizeLegacyToV2(316);
      border-radius: responsiveSizeLegacyToV2(27);
      .ks-IndexOverview-results-title {
        font-family: 'Fredoka One';
        font-size: responsiveSizeLegacyToV2(30);
        margin-top: responsiveSizeLegacyToV2(11);
      }
      .ks-IndexOverview-results-desc {
        line-height: 1.1;
        font-weight: 200;
        font-family: 'Avenir';
        font-size: responsiveSizeLegacyToV2(29);
        width: responsiveSizeLegacyToV2(554);
        height: responsiveSizeLegacyToV2(98);
        margin-top: responsiveSizeLegacyToV2(6);
        margin-bottom: responsiveSizeLegacyToV2(6);
      }
      svg.ks-chapter3-homepage-session-stars {
        width: responsiveSizeLegacyToV2(205);
        height: responsiveSizeLegacyToV2(54);
      }
      .ks-chapter3-homepage-session-btn-qm {
        margin-top: responsiveSizeLegacyToV2(16);
        a.mainBtn {
          display: flex;
          color: white;
          align-items: center;
          justify-content: center;
          text-transform: uppercase;
          font-family: "Fredoka One";
          color: black;
          background-color: #FCC566;
          width: responsiveSizeV2(281);
          height: responsiveSizeV2(62);
          font-size: responsiveSizeV2(24);
          border-radius: responsiveSizeV2(15);
        }
      }
      &.locked {
        .mainBtn {
          pointer-events: none;
          color: white !important;
          background-color: #B9C8C5 !important;
        }
        .mainBtn,
        .ks-chapter3-homepage-redo-btn,
        .ks-chapter3-homepage-pdf-btn {
          pointer-events: none;
        }

        .ks-chapter3-homepage-redo-btn,
        .ks-chapter3-homepage-pdf-btn {
          opacity: 0.3;
        }
        .locked-image {
          position: absolute;
          top: responsiveSizeV2(85);
          width: responsiveSizeV2(410);
          height: responsiveSizeV2(517);
        }
      }
    }
  }
  .ks-IndexOverview-frame-close {
    z-index: 10;
    cursor: pointer;
    position: absolute;
    top: responsiveSizeLegacyToV2(7);
    right: responsiveSizeLegacyToV2(28);
    width: responsiveSizeLegacyToV2(84);
    height: responsiveSizeLegacyToV2(84);
    circle {
      fill: none;
    }
    path {
      fill: white;
    }
  }
}
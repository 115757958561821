@import '../../common.scss';

.ks-register-newsletter {
  width: responsiveSizeV2(1080);
  display: flex;
  position: relative;
  background: linear-gradient(0deg, #E6C073, #EDCB74);

  svg.ks-register-newsletter-img {
    z-index: 2;
    width: 100%;
    height: 100%;
    position: relative;
  }

  .ks-register-newsletter-logo {
    display: none;
  }

  .ks-register-newsletter-title {
    display: none;
  }

  .ks-register-newsletter-form,
  .ks-register-newsletter-form-bkg {
    z-index: 1;
    position: absolute;
    background-color: white;
    margin-top: responsiveSizeV2(55);
    right: responsiveSizeV2(64);
    width: responsiveSizeV2(708);
    height: responsiveSizeV2(480);
    border-radius: responsiveSizeV2(25);
    margin-left: responsiveSizeV2(30);
  }

  .ks-register-newsletter-form {
    z-index: 3;
    color: black;
    font-family: 'Avenir';
    background-color: transparent;
    display: flex;
    flex-direction: column;
    padding: responsiveSizeV2(38);
    box-sizing: border-box;

    label {
      color: #51AABC;
      font-weight: 900;
      font-size: responsiveSizeV2(22);
      margin-bottom: responsiveSizeV2(6);

      span {
        font-weight: 400;
      }
    }

    input {
      font-family: 'Avenir';
      margin-bottom: responsiveSizeV2(13.5);
      height: responsiveSizeV2(66);
      border: responsiveSizeV2(4) solid #51AABC;
      border-radius: responsiveSizeV2(11);
      padding-left: responsiveSizeV2(31);
      font-size: responsiveSizeV2(22);
    }

    .ks-register-newsletter-btn-mention {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: responsiveSizeV2(24);

      button {
        cursor: pointer;
        border-radius: responsiveSizeV2(4);
        width: fit-content;
        display: flex;
        height: responsiveSizeV2(51);
        align-items: center;
        justify-content: center;
        color: white;
        background-color: #51AABC;
        font-family: 'Avenir';
        font-weight: 800;
        font-size: responsiveSizeV2(29);
        border: none;
        padding: responsiveSizeV2(6) responsiveSizeV2(78);

        &[disabled] {
          cursor: default;
          opacity: 0.4;
        }
      }

      p {
        color: #4CB3C4;
        font-size: responsiveSizeV2(22);
        font-weight: 300;
        text-align: center;
        margin-top: responsiveSizeV2(37);
      }
    }
  }

  width: 100vw;
  height: var(--vh);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: inherit;
  background-color: #323334;

  svg.ks-register-newsletter-img {
    display: none;
  }

  .ks-register-newsletter-logo {
    display: block;
    width: responsiveSizeV2(139);
    height: responsiveSizeV2(125);
  }

  .ks-register-newsletter-title {
    width: 90vw;
    display: block;
    color: #EDCB74;
    font-weight: 900;
    text-align: center;
    font-family: 'Fredoka One';
    font-size: responsiveSizeV2(35);
  }

  .ks-register-newsletter-form-bkg {
    display: none;
  }

  .ks-register-newsletter-form {
    position: relative;
    background-color: white;
    right: 0;
    padding: responsiveSizeV2(38);
    margin-top: responsiveSizeV2(55);
    width: responsiveSizeV2(480);
    height: fit-content;
    border-radius: responsiveSizeV2(25);
    margin-top: responsiveSizeV2(25);
    margin-bottom: responsiveSizeV2(25);

    label {
      font-size: responsiveSizeV2(22);
      margin-bottom: responsiveSizeV2(6);

    }

    input {
      margin-bottom: responsiveSizeV2(13.5);
      height: responsiveSizeV2(66);
      border: responsiveSizeV2(4) solid #51AABC;
      border-radius: responsiveSizeV2(11);
      padding-left: responsiveSizeV2(31);
      font-size: responsiveSizeV2(22);
    }

    .ks-register-newsletter-btn-mention {
      margin-top: responsiveSizeV2(24);

      button {
        border-radius: responsiveSizeV2(4);
        height: responsiveSizeV2(51);
        font-size: responsiveSizeV2(29);
        padding: responsiveSizeV2(6) responsiveSizeV2(78);
      }

      p {
        font-size: responsiveSizeV2(22);
        margin-top: responsiveSizeV2(37);
      }
    }
  }

  @media only screen and (max-width: 768px) and (-webkit-max-device-pixel-ratio: 5) {

    .ks-register-newsletter-logo {
      width: responsiveSizeMobileNewsletter(139);
      height: responsiveSizeMobileNewsletter(125);
    }

    .ks-register-newsletter-title {
      font-size: responsiveSizeMobileNewsletter(35);
    }

    .ks-register-newsletter-form-bkg {
      display: none;
    }

    .ks-register-newsletter-form {
      padding: responsiveSizeMobileNewsletter(38);
      margin-top: responsiveSizeMobileNewsletter(55);
      width: responsiveSizeMobileNewsletter(480);
      border-radius: responsiveSizeMobileNewsletter(25);
      margin-top: responsiveSizeMobileNewsletter(25);
      margin-bottom: responsiveSizeMobileNewsletter(25);

      label {
        font-size: responsiveSizeMobileNewsletter(22);
        margin-bottom: responsiveSizeMobileNewsletter(6);

      }

      input {
        margin-bottom: responsiveSizeMobileNewsletter(13.5);
        height: responsiveSizeMobileNewsletter(66);
        border: responsiveSizeMobileNewsletter(4) solid #51AABC;
        border-radius: responsiveSizeMobileNewsletter(11);
        padding-left: responsiveSizeMobileNewsletter(31);
        font-size: responsiveSizeMobileNewsletter(22);
      }

      .ks-register-newsletter-btn-mention {
        margin-top: responsiveSizeMobileNewsletter(24);

        button {
          border-radius: responsiveSizeMobileNewsletter(4);
          height: responsiveSizeMobileNewsletter(51);
          font-size: responsiveSizeMobileNewsletter(29);
          padding: responsiveSizeMobileNewsletter(6) responsiveSizeMobileNewsletter(78);
        }

        p {
          font-size: responsiveSizeMobileNewsletter(22);
          margin-top: responsiveSizeMobileNewsletter(37);
        }
      }
    }
  }
}
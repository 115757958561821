@import '../../common.scss';

.ks-contactus {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  background: linear-gradient(0deg, #E6C073, #EDCB74);

  svg {
    z-index: 2;
    width: 100%;
    height: 100%;
    position: relative;
  }
  .ks-contactus-form,
  .ks-contactus-form-bkg {
    z-index: 1;
    position: absolute;
    background-color: white;
    margin-top: responsiveSizeV2(55);
    right: responsiveSizeV2(64);
    width: responsiveSizeV2(708);
    height: responsiveSizeV2(865);
    border-radius: responsiveSizeV2(25);
    margin-left: responsiveSizeV2(30);
  }
  .ks-contactus-form {
    z-index: 3;
    color: black;
    font-family: 'Avenir';
    background-color: transparent;
    display: flex;
    flex-direction: column;
    padding: responsiveSizeV2(38);
    box-sizing: border-box;

    label {
      color: #51AABC;
      font-weight: 900;
      font-size: responsiveSizeV2(22);
      margin-bottom: responsiveSizeV2(6);

      span {
        font-weight: 400;
      }
    }

    textarea,
    input {
      font-family: 'Avenir';
      margin-bottom: responsiveSizeV2(13.5);
      height: responsiveSizeV2(66);
      border: responsiveSizeV2(4) solid #51AABC;
      border-radius: responsiveSizeV2(11);
      padding-left: responsiveSizeV2(31);
      font-size: responsiveSizeV2(22);
    }

    textarea {
      height: responsiveSizeV2(320);
      padding-top: responsiveSizeV2(19);
    }

    .messageLottie {
      position: absolute;
      z-index: 12;
      bottom: responsiveSizeV2(260);
      left: responsiveSizeV2(228);
      width: responsiveSizeV2(253);
      height: responsiveSizeV2(253);

      .hide {
        position: absolute;
        opacity: 0;
      }

      .display {
        opacity: 1;
        transition: 1s opacity ease-in;
      }
    }

    .ks-contactus-btn-mention {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: responsiveSizeV2(24);

      button {
        cursor: pointer;
        border-radius: responsiveSizeV2(4);
        width: fit-content;
        display: flex;
        height: responsiveSizeV2(51);
        align-items: center;
        justify-content: center;
        color: white;
        background-color: #51AABC;
        font-family: 'Avenir';
        font-weight: 800;
        font-size: responsiveSizeV2(29);
        border: none;
        padding: responsiveSizeV2(6) responsiveSizeV2(78);

        &[disabled] {
          cursor: default;
          opacity: 0.4;
        }
      }

      p {
        color: #4CB3C4;
        font-size: responsiveSizeV2(22);
        font-weight: 300;
        text-align: center;
        margin-top: responsiveSizeV2(37);
      }
    }
  }
}
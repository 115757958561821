@import '../../common.scss';

.ks-loginV3 {
  height: 100%;
  min-width: 100%;
}

.ks-embedded-authentication {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;

  .ks-avatar-selector {
    transform: scale(0.72);
    padding-right: responsiveSizeToV2(10);
    padding-left: responsiveSizeToV2(10);
  }

  .ks-form-title {
    display: flex;
    color: #DDDDDD;
    align-items: center;
    margin-left: responsiveSizeToV2(100);
    margin-bottom: responsiveSizeToV2(20);
    font-size: responsiveSizeToV2(25);

    span {
      cursor: pointer;
      font-family: 'Fredoka One';
    }

    span.selected {
      color: #51AABC;
      cursor: default;
      text-decoration: underline;
    }
  }

  .ks-loginV3 {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    justify-content: center;
    background-color: white;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    border-radius: responsiveSizeToV2(42);

    .ks-form-left {
      display: flex;
      align-items: flex-end;
      flex-direction: column;
      width: responsiveSizeToV2(600);
      height: responsiveSizeToV2(654);

      .ks-form {
        display: flex;
        flex-direction: column;
        width: 92%;

        .ks-form-group {
          position: relative;

          .password-wrapper {
            display: contents;

            .eye-icon-wrapper {
              right: 0;
              bottom: 0;
              cursor: pointer;
              position: absolute;
              width: responsiveSizeToV2(68);
              height: responsiveSizeToV2(68);
              display: flex;
              align-items: center;
              justify-content: center;

              .eye-icon {
                display: flex;
                color: #C3C3C3;
                font-size: responsiveSizeToV2(28);

                &.bi-eye::before,
                &.bi-eye-slash::before {
                  line-height: 0;
                }
              }
            }
          }

          input.ks-form-control {
            border: none;
            background-color: #F2F2F2;

            &:disabled {
              opacity: 0.5;
            }
          }
        }
      }

      .ks-formBtn {
        width: 92%;
        display: flex;
        justify-content: center;
        margin-top: responsiveSizeToV2(15);

        .mainBtn {
          font-weight: 900;
          font-family: 'Avenir';
          width: responsiveSizeToV2(226);
          height: responsiveSizeToV2(54);
          font-size: responsiveSizeToV2(26);
        }
      }

      .ks-forgetBtn {
        font-size: responsiveSizeToV2(19);
        font-style: normal;
      }
    }

    .ks-form-group {
      label {
        font-family: 'Avenir';
        font-weight: 900;
        font-size: responsiveSizeToV2(24);
      }
    }

    .ks-form-right {
      width: responsiveSizeToV2(484);
      display: flex;
      justify-content: flex-end;
      margin-right: responsiveSizeToV2(10);
    }

    .ks-signin-img {
      svg {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.ks-embedded {
  .ks-login {
    box-shadow: none;

    .ks-formBtn {
      display: flex;
      align-items: center;
      flex-direction: column;

      .mainBtn {
        font-family: 'Avenir';
        font-weight: 700;
        display: flex;
        padding-bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        height: responsiveSizeToV2(58);
        width: responsiveSizeToV2(240);
        font-size: responsiveSizeToV2(24);
      }

      .ks-forgetBtn {
        border: none;
        background-color: transparent;
        font-family: 'Avenir';
        font-style: oblique;
        text-decoration: underline;
        font-size: responsiveSizeToV2(13);
        margin-top: responsiveSizeToV2(13);
      }
    }
  }

  .ks-loginTitle,
  .hp-signin-closeBtn {
    display: none;
  }
}
@import '../../../common.scss';

.hp-layoutV2 {
  .ks-timer-display {
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    height: responsiveSizeV2(69);
    .ks-counter {    
      height: 100%;
      color: white;
      font-family: 'Inter';
      box-sizing: border-box;
      font-size: responsiveSizeV2(44);
      margin-left: responsiveSizeV2(9);
      border-top: responsiveSizeV2(3) solid #707070;
      border-bottom: responsiveSizeV2(3) solid #707070;
      .ks-minutesSeconds {
        margin-top: responsiveSizeV2(8);
        font-feature-settings: "tnum";
        font-variant-numeric: tabular-nums;
      }
    }
    svg {
      width: responsiveSizeV2(65);
      height: responsiveSizeV2(72);
    }
  }
}
@import '../../common.scss';

.ks-profilForm {
  width: 100%;
  .ks-profil{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .ks-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    .ks-form-group {
      width: 100%;
      @include responsive-size(margin-bottom, 19);
    }
    .ks-form-control-choice {
      display: flex;
      .ks-btnChoice {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #C3C3C3;
        font-family: 'Avenir';
        font-weight: 300;
        color: #C3C3C3;
        @include responsive-size(margin-top, 5);
        @include responsive-size(font-size, 26);
        @include responsive-size(border-radius, 10);
        @include responsive-size(padding-left, 5);
        @include responsive-size(padding-right, 5);
        @include responsive-size(min-width, 146);
        @include responsive-size(max-width, 154);
        @include responsive-size(height, 72);
        &:not(:last-child) {
          @include responsive-size(margin-right, 14);
        }
        &.ks-selected {
          color: white;
          font-weight: 700;
          background-color: #EAC676;
        }
      }
    }
  }
  label {
    font-family: 'Avenir';
    font-weight: 700;
    @include responsive-size(font-size, 22);
  }
  input {
    @include responsive-size(font-size, 30);
  }
  .mainBtn {
    font-family: 'Avenir';
    font-weight: 700;
    @include responsive-size(font-size, 24);
    @include responsive-size(width, 237);
    @include responsive-size(height, 58);
  }
}
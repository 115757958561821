@import '../../common.scss';

.ks-chapterMap.hp-layoutV2 {
  .ks-chapterMapContent {
    .ks-leftPanel{
      display: none;
    }
    .ks-chapterTitleImage {
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.ks-readwrite-homepage {
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  .ks-readwrite-homepage-title {
    width: responsiveSizeV2(325);
    height: responsiveSizeV2(226);
    margin-top: responsiveSizeV2(160);
  }
}
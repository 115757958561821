@import '../../../common.scss';

.hp-layoutV2.ks-phase3 {
  a { text-decoration: none; }

  .star-enabled-1 {
    #star_1 { fill: #FFAC32}
  }
  .star-enabled-2 {
    #star_1, #star_2 { fill: #FFAC32}
  }
  .star-enabled-3 {
    #star_1, #star_2, #star_3 { fill: #FFAC32}
  }
  .hp-leftArea { 
    margin-top: var(--headerHeight);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .ks-phase3-chapterSelected {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      flex-direction: column;
      width: responsiveSizeV2(325);
      height: responsiveSizeV2(226);
      border-radius: responsiveSizeV2(20);
      margin-top: responsiveSizeV2(130);
      background-color: #1A5560;
      .ks-phase3-chapterTitle-arabic { 
        &, svg {
          width: responsiveSizeV2(108);
          height: responsiveSizeV2(32);
        }
      }
      .ks-phase3-chapterTitle-latin {
        &, svg {
          width: responsiveSizeV2(186);
          height: responsiveSizeV2(25);
        }
      }
      .phase_icon {
        &, svg {
          width: responsiveSizeV2(82);
          height: responsiveSizeV2(81);
        }
      }
    }
    a {
      margin-top: responsiveSizeV2(25);
      &, svg {
        cursor: pointer;
        width: responsiveSizeV2(281);
        height: responsiveSizeV2(62);
      }
    }
    .not-selected-chapter {
      margin-top: responsiveSizeV2(242);
      .ks-phase3-chapterBtn {
        width: responsiveSizeV2(324);
        height: responsiveSizeV2(66);
        border-radius: responsiveSizeV2(16);
        background-color: #1A5560;
        display: flex;
        align-items: center;
        &, svg {cursor: pointer;}
        &.ks-disabled { &, svg {cursor: not-allowed; pointer-events: none;}}
        &:not(:first-child) {margin-top: responsiveSizeV2(14);}
        .ks-phase3-chapterBtn-latin {
          height: 70%;
          margin-top: responsiveSizeV2(3);
          width: responsiveSizeV2(192);
          display: flex;
          align-items: center;
          justify-content: center;
          border-right: responsiveSizeV2(2) solid #749DA5;
          svg {
            width: responsiveSizeV2(146);
            height: responsiveSizeV2(19);
          }
          
        }
        .ks-phase3-chapterBtn-arabic {
          margin-top: responsiveSizeV2(5);
          margin-left: responsiveSizeV2(8);
          &, svg {
            width: responsiveSizeV2(90);
            height: responsiveSizeV2(25);
          }
        }
      }
    }
     
    // Added for the V3. (REACT_APP_FEATURE_V3)
    .ks-chapter-list {
      display: flex;
      flex-direction: column;
      button,
      a {
        opacity: 0.2;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        text-transform: uppercase;
        font-family: 'Fredoka One';
        border: 2px solid white;
        width: responsiveSizeV2(280);
        height: responsiveSizeV2(60);
        font-size: responsiveSizeV2(24);
        border-radius: responsiveSizeV2(30);
        margin-top: responsiveSizeV2(15);
        &.selected {
          opacity: 1;
          background-color: #60AFA2;
        }
      }
    }
  }

  &.ks-phase3V2 {
    .hp-leftArea {
      a.ks-phase3-chapterBtn.lessons.selected {
        color: black;
        border-color: #FCC566;
        background-color: #FCC566;
      }
      a.ks-phase3-chapterBtn.vocabulary.selected {
        color: black;
        border-color: #5DBAA8;
        background-color: #5DBAA8;
      }

      .ks-phase3-chapterBtn.index {
        color: black;
        opacity: 1;
        position: relative;
        border-color: white;
        background-color: white;
        svg {
          position: absolute;
          left: responsiveSizeV2(27);
          width: responsiveSizeV2(44);
          height: responsiveSizeV2(33);
        }
        &.open {
          border-color: #51AABC;
          background-color: #51AABC;
        }
      }
      .ks-chapter-stars {
        display: flex;
        position: relative;
        align-items: center;
        justify-content: center;
        width: responsiveSizeV2(280);
        height: responsiveSizeV2(75);
        margin-top: responsiveSizeV2(17);
        border: 2px solid #DDDDDD;
        border-radius: responsiveSizeV2(20);
        font-family: 'Fredoka One';
        font-size: responsiveSizeV2(44);
        color: white;
        svg {
          position: absolute;
          width: responsiveSizeV2(43);
          height: responsiveSizeV2(43);
          left: responsiveSizeV2(24);
        }
      }
    }
  }

  .ks-chapter3-homepage-nav-left,
  .ks-chapter3-homepage-nav-right {
    z-index: 10;
    position: absolute;
    top: responsiveSizeV2(477);
    left: responsiveSizeV2(13);
    &, svg {
      width: responsiveSizeV2(90);
      height: responsiveSizeV2(90);
    }
  }
  .ks-chapter3-homepage-nav-right {
    left: inherit;
    right: responsiveSizeV2(13);
  }
  .ks-chapter3-homepage {
    .landscape_hidden {
      display: none !important;
    }
    width: 100%;
    height: 100%;
    background-color: #1A5560;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;

    overflow: hidden;
    align-items: center;
    &.scrollable,
    &.chapter1622868529412-1,
    &.chapter1622868529412-2,
    &.chapter1622868529412-3 {
      align-items: flex-start;
      overflow-x: scroll;
      overflow-y: hidden;
      scrollbar-width: none; /* Firefox */
      -ms-overflow-style: none;  /* Internet Explorer 10+ */
      &::-webkit-scrollbar { /* WebKit */
        width: 0;
        height: 0;
      }
    }
    .ks-chapter3-homepage-container {
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: responsiveSizeV2(110);
      margin-right: responsiveSizeV2(110);
      .ks-chapter3-homepage-session {
        display: flex;
        position: relative;
        background-color: white;
        width: responsiveSizeV2(457);
        height: responsiveSizeV2(912);
        border-radius: responsiveSizeV2(28);
        &:not(:last-child) {
          margin-right: responsiveSizeV2(48);
        }
        align-items: center;
        flex-direction: column;
        .ks-chapter3-homepage-session-stars {
          width: responsiveSizeV2(215);
          height: responsiveSizeV2(57);
          margin-top: responsiveSizeV2(18);
          margin-bottom: responsiveSizeV2(10);
        }
        a.mainBtn {
          display: flex;
          color: white;
          align-items: center;
          justify-content: center;
          text-transform: uppercase;
          font-family: "Fredoka One";
          background-color: #51AABC;
          width: responsiveSizeV2(281);
          height: responsiveSizeV2(62);
          font-size: responsiveSizeV2(24);
          margin-top: responsiveSizeV2(50);
          border-radius: responsiveSizeV2(62);
        }
        .ks-chapter3-homepage-session-title {
          z-index: 1;
          position: absolute;
          top: responsiveSizeV2(577);
          &, svg {
            width: responsiveSizeV2(307);
            height: responsiveSizeV2(62);
          }
        }
        .ks-chapter3-homepage-session-image {
          &, svg {
            width: responsiveSizeV2(410);
            height: responsiveSizeV2(517);
          }
        }
        .ks-chapter3-homepage-redo-btn,
        .ks-chapter3-homepage-pdf-btn {
          height: auto;
          width: responsiveSizeV2(297);
          margin-top: responsiveSizeV2(12);
        }

        .ks-chapter3-homepage-redo-btn {margin-top: responsiveSizeV2(8);}
        &.locked {
          .mainBtn {
            pointer-events: none;
            background-color: #DDDDDD;
          }
          .mainBtn,
          .ks-chapter3-homepage-redo-btn,
          .ks-chapter3-homepage-pdf-btn {
            pointer-events: none;
          }

          .ks-chapter3-homepage-redo-btn,
          .ks-chapter3-homepage-pdf-btn {
            opacity: 0.3;
          }
          .locked-image {
            position: absolute;
            top: responsiveSizeV2(85);
            width: responsiveSizeV2(410);
            height: responsiveSizeV2(517);
          }
        }
      
        &.ks-session-revision {
          background-color: #51AABC;
          .ks-chapter3-homepage-session-stars,
          .ks-chapter3-homepage-pdf-btn,
          .ks-chapter3-homepage-redo-btn {
            display: none;
          }
          .ks-chapter3-homepage-session-revision-icon {
            position: absolute;
            top: responsiveSizeV2(226);
            width: responsiveSizeV2(153);
            height: responsiveSizeV2(200);
          }
  
          .ks-chapter3-homepage-session-title {
            position: absolute;
            top: responsiveSizeV2(457);
            &, svg {
              width: responsiveSizeV2(244);
              height: responsiveSizeV2(99);
            }
          }

          .mainBtn {
            color: #51AABC;
            position: absolute;
            top: responsiveSizeV2(599);
            background-color: white;
          }
          &.locked {
            background-color: #73988F;
            .mainBtn {
              color: #73988F;
            }
            .locked-image {
              display: none;
            }
  
          }
        }
      }
    }
  }
}

.hp-layoutV2.ks-phase3.hp-layoutV3 .hp-leftArea {
  display: flex;
  justify-content: flex-start;
  .ks-awlad-logo {
    cursor: pointer;
    width: responsiveSizeLegacyToV2(313);
    height: responsiveSizeLegacyToV2(125);
  }
  .ks-phase3-chapterSelected {
    width: responsiveSizeLegacyToV2(320);
    height: responsiveSizeLegacyToV2(220);
    margin-top: responsiveSizeV2(17);
  }
}

.portrait {
  div.ks-phase3.hp-layoutV2 {
    .portrait_hidden {
      display: none !important;
    }
    flex-direction: column;
    .hp-leftArea { 
      display: none;
    }
    #mainContentScreen.ks-content {
      height: fit-content;
      margin-top: 0;
    }
  }
}

// Specific to the lessons and vocabulary

.ks-chapter-lessons, 
.ks-chapter-vocabulary {
  .hp-layoutV2.ks-phase3 .ks-chapter3-homepage .ks-chapter3-homepage-container .ks-chapter3-homepage-session {
    
    &.ks-session-revision {
      .ks-chapter3-homepage-session-btn-qm {
        position: absolute;
        bottom: responsiveSizeLegacyToV2(50);
        a.mainBtn {
          top: 0;
          margin: 0;
          position: relative;
        }
      }
    }
    &.locked {
      a.mainBtn {
        color: white;
        background-color: #B9C8C5;
      }
    }
    .ks-chapter3-homepage-session-text {
      text-align: center;
      margin-top: responsiveSizeLegacyToV2(60);
      .ks-chapter3-homepage-session-label {
        font-weight: 900;
        font-family: 'Fredoka One';
        width: responsiveSizeLegacyToV2(410);
        height: responsiveSizeLegacyToV2(34);
        font-size: responsiveSizeLegacyToV2(28);
      }
      .ks-chapter3-homepage-session-desc {
        line-height: 1.1;
        font-weight: 300;
        font-family: 'Avenir';
        width: responsiveSizeLegacyToV2(410);
        height: responsiveSizeLegacyToV2(88);
        font-size: responsiveSizeLegacyToV2(23);
        margin-top: responsiveSizeLegacyToV2(14);
      }
    }
  }
}

// Specific to only lessons

.ks-chapter-lessons {
  .hp-layoutV2.ks-phase3 .ks-chapter3-homepage .ks-chapter3-homepage-container .ks-chapter3-homepage-session {

    a.mainBtn {
      color: black;
      background-color: #FCC566;
    }
    .ks-chapter3-homepage-session-title {
      svg path {
        fill: #CB963A;
      }
    }

    &.ks-session-revision {
      .ks-chapter3-homepage-session-title {
        svg path {
          fill: white;
        }
      }
    }

    .ks-chapter3-homepage-session-desc {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.ks-chapter-vocabulary {
  .hp-layoutV2.ks-phase3 {
    #nav_arrow,
    #nav_arrow_right {
      fill: #5DBAA8;
    }
    .ks-chapter3-homepage .ks-chapter3-homepage-container .ks-chapter3-homepage-session {
      a.mainBtn {
        margin-top: responsiveSizeV2(40);
        background-color: #5DBAA8;
      }
      .locked-image {
        rect {
          fill: #5DBAA8;
        }
      }
    }
  }
}

.ks-chapter-lessons, 
.ks-chapter-vocabulary {
  .ks-chapter3-homepage-session-btn-qm {
    position: relative;
    margin-top: responsiveSizeLegacyToV2(26);
    a.mainBtn {
      margin: 0 !important;
      position: relative;
    }
    .ks-chapter3-homepage-session-qm {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      cursor: pointer;
      position: absolute;
      svg {
        cursor: pointer;
        position: absolute;
        top: responsiveSizeLegacyToV2(7);
        left: responsiveSizeLegacyToV2(260);
        width: responsiveSizeLegacyToV2(39);
        height: responsiveSizeLegacyToV2(39);
      }
    }
  }
}

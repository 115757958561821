@import '../../common.scss';

.ks-waladCard {
  svg {
    @include responsive-size(width, 149);
    @include responsive-size(height, 206);
  }
  .ks-waladCard-thumbnail{
    display: flex;
    align-items: center;
    justify-content: center;
    &.ks-cardLoader > div {
      box-shadow: inset 0px 0px 0px 2px #c39745;
      &::after,
      &::before {
        background-color: #c39745;
      }
    }
    &, svg {
      @include responsive-size(width, 149);
      @include responsive-size(height, 206);
    }
  }
}
.ks-awlad-logo {
  width: 300px;
  max-width: 90%;

  path {
    fill: #51AABC
  }
}

.loading-spinner svg #spinner-bar {
  --spinner-bar-fill: #51AABC;
}
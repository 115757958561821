@import '../../../common.scss';

.ReactModalPortal.ks-sessionImageModal {
  .ReactModal__Overlay {
    z-index: 54;
  }
}

.ks-screensList {
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  display: flex;
  flex-wrap: wrap;
  @include responsive-size(width, 200);
  > div {
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid black;
    @include responsive-size(font-size, 16);
    @include responsive-size(width, 30);
    @include responsive-size(height, 30);
    &.ks-screenSelected {
      background-color: #51AABC;
      color: white;
    }
  }
}
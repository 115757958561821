@import "../../../../common.scss";

.section3 {
  height: responsiveSizeV2(750);
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(to bottom, #51AABC responsiveSizeV2(286), #AFCDE1);
  h2 {
    color: white;
    text-align: center;
    font-family: 'minion-pro';
    font-size: responsiveSizeV2(42);
    font-weight: bold;
    margin-top: responsiveSizeV2(25);
    margin-bottom: responsiveSizeV2(18);
    height: fit-content;
    line-height: responsiveSizeV2(45);
  }
  .scrollable-frame {
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    &::-webkit-scrollbar { /* WebKit */
      width: 0;
      height: 0;
    }
    @keyframes scroll{
      0% {
        transform: translateX(0);
      }
      100% {
        transform: translateX(calc(responsiveSizeV2(-550) * 3));
      }
    }
    .testimony-list {
      display: flex;
      width: fit-content;
      animation: scroll 20s linear infinite;
      &:hover {
        animation: none;
      }
      .testimony {
        margin-left: responsiveSizeV2(33);
        &:last-child {
          margin-right: responsiveSizeV2(33);
        }
      }
    }
  }
  .awlad-boat {
    flex: 1;
    display: flex;
    position: relative;
    align-items: flex-end;
    overflow: hidden;
    margin-top: responsiveSizeV2(15);
    .boat {
      z-index: 2;
      position: absolute;
      bottom: responsiveSizeV2(-60);
      left: responsiveSizeV2(150);
      width: responsiveSizeV2(542);
      height: responsiveSizeV2(361);
    }
    .waves {
      z-index: 3;
      position: relative;
      margin-bottom:responsiveSizeV2( -33) !important;
      width: 100%;
    }
  }
}

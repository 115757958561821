@import '../../common.scss';

.ks-loadingPage {
  width: 100vw;
  height: 100vh;
  position: absolute;
  z-index: 60;
  background-color: black;
  top: 0;
  left: 0;
  .ks-loadingPage-logoWrapper {
    margin: auto;
    margin-top: 5%;
    margin-bottom: 1.4%;
    &, svg {
      @include responsive-size(width, 330.65);
      @include responsive-size(height, 144.61);
    }
  }
  .ks-loadingPage-lottieWrapper {
    margin: auto;
    background-color: white;
    border-radius: 100%;
    margin-bottom: 1%;
    &, svg {
      @include responsive-size(width, 555);
      @include responsive-size(height, 555);
    }
  }
  .ks-loadingPage-messageWrapper {
    margin: auto;
    text-align: center;
    color: #91D2F6;
    font-family: 'Fredoka One';
    @include responsive-size(font-size, 48);

  }
}
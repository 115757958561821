@import "common.scss";

.custom-button {
  display: flex;
  align-items: center;
  text-align: center;
  margin-top: responsiveSizeV2(15);
  cursor: pointer;
  width: responsiveSizeV2(325);
  height: responsiveSizeV2(77.4);
  border-radius: responsiveSizeV2(20);
  justify-content: center;
  border: responsiveSizeV2(3) solid #dddddd;
  &.hasIcon {
    justify-content: flex-start;
  }
  span {
    font-size: responsiveSizeV2(28);
    font-weight: 900;
  }
  color: #fff;
  svg {
    margin-left: responsiveSizeV2(28);
    margin-right: responsiveSizeV2(38);

    &.lock {
      width: responsiveSizeV2(24.57);
      height: responsiveSizeV2(32.76);
    }
    &.home {
      width: responsiveSizeV2(31.89) !important;
      height: responsiveSizeV2(31.9) !important;
    }
  }
  &.ks-disabled {
    cursor: not-allowed;
    font-size: responsiveSizeV2(25) !important;
    font-weight: 600;
    color: #dddddd;
  }

  &.ks-home {
    background-color: #fff;
    color: black;
    width: responsiveSizeV2(281);
    height: responsiveSizeV2(61);
    border-radius: 42px;
  }
}

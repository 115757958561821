@import "common.scss";

.alphabets-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  direction: rtl;
  padding: responsiveSizeV2(44) responsiveSizeV2(47);
  margin-right: responsiveSizeV2(67);
  width: responsiveSizeV2(263);
  height: responsiveSizeV2(900);
  border: responsiveSizeV2(2) solid #dddddd;
  border-radius: responsiveSizeV2(17);
  column-gap: responsiveSizeV2(36);
  row-gap: responsiveSizeV2(17);
  align-content: space-evenly;
  justify-items: center;
  background-color: white;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  &.loading {
    grid-template-columns: 1fr;
  }
  &.flex-start {
    align-content: flex-start;
  }
  button {
    cursor: pointer;
  }
  .remake-audio {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    position: absolute;
    bottom: responsiveSizeV2(26);
    right: responsiveSizeV2(240);
    width: responsiveSizeV2(212);
    height: responsiveSizeV2(152);
    border-radius: responsiveSizeV2(15) responsiveSizeV2(15)
      responsiveSizeV2(15) responsiveSizeV2(50);
    background: #51aabc;
    border: responsiveSizeV2(4) solid #fff;
    svg {
      width: responsiveSizeV2(173.693);
      height: responsiveSizeV2(79.29);
      cursor: pointer;
      margin-bottom: 8px;
    }
    span {
      font-family: "Fredoka One";
      font-weight: normal;
      font-size: responsiveSizeV2(26);
      letter-spacing: -0.03em;
      line-height: responsiveSizeV2(31);
      text-align: center;
      color: #fff;
    }
  }
}

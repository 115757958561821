@import '../../../common.scss';

$base-color-2: #5DBAA8;

.ks-chapter-1622868529412-2 {
  .hp-layoutV2 {
  #nav_arrow,
  #nav_arrow_right {
    fill: $base-color-2;
  }
  .ks-audio-playing {
    background-color: $base-color-2;
  }
  .ks-number-pad2 {
    width: responsiveSizeV2(245);
    height: responsiveSizeV2(263);
    .ks-interface-selected {
      circle[id^="ellipse"] {
        fill: $base-color-2;
      }
    }
  }
  .ks-letterWrittenInWords {
    padding-top: 0;
  }
  .ks-letterWrittenInWords-navigation {
    flex-wrap: wrap;
    justify-content: space-around;
    .ks-letterWrittenInWords-nameMenu {
      margin-left: 0;
      &, .ks-letter svg {
        width: responsiveSizeV2(200);
      }
    }
  }
  .ks-dialogue {
    .ks-navigation-wrapper .ks-navigation-play {
      div[class^="ks-play"],
      div[class^="ks-navigation"] {
        svg {
          path {
            fill: $base-color-2;
          }
          g#RELANCER {
            circle { fill: $base-color-2; }
            path { fill: white; }
          }
        }
      }
      div.ks-btnEnabledEvenWhenSoundOn {
        svg {
          path {
            fill: rgb(234, 198, 118);
          }
        }
      }

      div.ks-disabled-arrow {
        svg path {
          fill: #DDDDDD;
        }
      }
    }
  }


  .ks-phase3-2 {
    background-color: $base-color-2;
    .ks-phase3-chapterTitle-arabic,
    .phase_icon {
      display: none;
    }
  }

  // Phase 3 home page
  &.ks-phase3 .hp-leftArea {
    .ks-phase3-chapterSelected {
      &.ks-phase3-2 {
        background-color: $base-color-2;
        .ks-phase3-chapterTitle-arabic,
        .phase_icon {
          display: none;
        }
      }
    }
  }

  &.ks-phase3 .ks-chapter3-homepage {
    // overflow: hidden;
    // align-items: center;
    .ks-chapter3-homepage-session {
      width: responsiveSizeV2(558);
      .ks-chapter3-homepage-session-image {
        &, svg {
          width: responsiveSizeV2(517);
          height: responsiveSizeV2(530);
        }
      }
      a.mainBtn {
        margin-top: responsiveSizeV2(40);
        background-color: $base-color-2;
      }
      &.locked .locked-image {    
        top: responsiveSizeV2(85);
        width: responsiveSizeV2(517);
        height: responsiveSizeV2(517);
      }
    }
  }

  .ks-dictation {
    background-color: $base-color-2;
    .ks-testProgress-panel { display: none; }
    ellipse[fill="#51aabc"],
    path[fill="#2f8495"],
    g[fill="#2f8495"] {
      fill: $base-color-2;
    }
    .ks-audio-playing {
      background-color: $base-color-2;
    }
  }

  .ks-letterWrittenInName {
    background-color: $base-color-2;
    .ks-letterWrittenInName-bkg {
      display: none;
    }
  }
  .ks-letterWrittenInWords .ks-letterWrittenInWords-letters {
    .ks-letterWrittenInWords-letterSound.ks-letterWrittenInWords-selected {
      .ks-audioBtn ellipse[fill="#51aabc"],
      .ks-letterWrittenInWords-letter svg rect[fill='#ddd'] {
        fill: $base-color-2;
      }
    }
  }

  &.hp-layoutV2 .ks-session-notion-popup {
    background-color: rgba($color: $base-color-2, $alpha: 0.9);
    .ks-session-notion-frame.ks-session-notion-frame-full .mainBtn {
      background-color: $base-color-2;
    }
  }
  }
}
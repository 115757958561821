@import "common.scss";

.notcorrected-container {
  direction: ltr;
  display: flex;
  justify-content: center;
  align-items: center;
  border: responsiveSizeV2(3) solid #dddddd;
  border-radius: responsiveSizeV2(11);
  background-color: white;
  height: responsiveSizeV2(105);
  width: responsiveSizeV2(112);
  box-shadow: 0px responsiveSizeV2(3) responsiveSizeV2(6) rgba(0, 0, 0, 0.16);
  &.current {
    border: responsiveSizeV2(4) solid #51aabc;
    box-shadow: none;
  }
  &.isRecorded {
    border: responsiveSizeV2(3) solid #51aabc;
    opacity: 30%;
    background-color: #51aabc;
  }
  .letter {
    width: 100%;
    height: 100%;
    svg {
      height: inherit;
      width: 100%;
      cursor: pointer;
    }
    &.disabled {
      opacity: 1;
      svg {
        cursor: not-allowed;
      }
    }
  }

  .current-arrow {
    position: absolute;
    transform: translate(responsiveSizeV2(73), 0%);
    height: responsiveSizeV2(25);
  }
}
@media only screen and (max-width: 970px) {
  .corrected-container {
    height: responsiveSizeV2(100);
  }
}

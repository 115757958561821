@import "common.scss";

.corrected-wrapper {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .container {
    background-color: white;
    margin-top: responsiveSizeV2(-140);
    margin-bottom: responsiveSizeV2(20);

    height: responsiveSizeV2(527);
    width: responsiveSizeV2(469);
    border-radius: 18px;
    box-shadow: 0px responsiveSizeV2(3) responsiveSizeV2(6) rgba(0, 0, 0, 0.16);

    &.true {
      border: 5px solid #5a9f6d;
    }
    &.false {
      border: 5px solid #ce6f3d;
    }
    .letter {
      height: 100%;
      width: 100%;
      margin-top: -4rem;
      svg {
        height: inherit;
        width: 100%;
      }
    }
    .corrections {
      display: flex;
      justify-content: space-between;
      margin: 0.5rem;
      &.flexend {
        justify-content: flex-end;
      }
    }
  }
}

@import "../../../../common.scss";

.section4 {
  background-color: #51AABC;

  .preview1,
  .preview2,
  .preview3 {
    width: responsiveSizeV2(955);
    margin: auto;
    padding-top: responsiveSizeV2(36);

    p {
      margin: 0;
      line-height: responsiveSizeV2(23);
    }
  }

  .preview1 {
    h2 {
      margin-top: responsiveSizeV2(28);
    }

    img {
      width: responsiveSizeV2(540);
      height: responsiveSizeV2(313);
    }
  }

  .preview2 {
    margin-top: responsiveSizeV2(37);
    direction: rtl;

    img {
      width: responsiveSizeV2(506);
      height: responsiveSizeV2(434);
    }

    .imageText-text {
      flex: 1;
      margin-left: 0;
      direction: ltr;
      text-align: right;
      margin-right: responsiveSizeV2(43);
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      h2,
      p {
        width: responsiveSizeV2(381);
        margin-left: responsiveSizeV2(33);
      }
    }
  }

  .preview3 {
    margin-top: responsiveSizeV2(37);
    padding-top: responsiveSizeV2(37);
    padding-bottom: responsiveSizeV2(89);

    img {
      width: responsiveSizeV2(540);
      height: responsiveSizeV2(313);
    }
  }

}
@import '../../common.scss';

.ks-audio-playing svg {
  @include responsive-size(height, 125);
  @include responsive-size(width, 125);
}

.ks-audio-playing {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #51AABC;
  border-radius: 8vw;
  .css-0 {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .css-1by9rry,
    .css-110do72 {
      width: 20%;
      height: 20%;
      margin: 0.2vw;
      background-color: white;
    }
  }
}
